import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const AvgDurationChart = ({ data }) => {
  const chartData = {
    labels: data?.avg_duration_per_question?.map((item) => item?._id),
    datasets: [
      {
        label: "Average Duration (seconds)",
        data: data?.avg_duration_per_question?.map((item) => item?.avgDuration),
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  return <Bar data={chartData} />;
};

export default AvgDurationChart;
