import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import LoadingAnimation from "components/Animation/LoadingAnimation";

import CategoryTable from "./data/CategoryTable";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import CategoryDetails from "./data/CategoryDetail";
import AddCategory from "./data/AddCategory";

function Category() {
  var { columns, rows, data, loading, refresh, setRefresh } = CategoryTable();

  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [value, setValue] = useState(0);

  const showCategoryModal = (category) => {
    console.log("category", category);
    setSelectedCategory(category);
    setShow(true);
  };

  const showAddCategory = () => {
    setShowAdd(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };

  const categoryUpdated = () => {
    // { columns, rows, data, loading } = CategoryTable();
    // rows = CategoryTable().rows;
    setShow(false);
    setShowAdd(false);
    setRefresh(refresh + 1);
    // window.location.reload();
  };
  useEffect(() => {
    handleClose();
  }, [refresh]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={2}
                py={1.5}
                px={1.5}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h5" color="white">
                  Categories
                </MDTypography>
                <Button
                  onClick={() => {
                    showAddCategory();
                  }}
                  variant="success"
                >
                  Create
                </Button>
              </MDBox>
              <MDBox>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    pagination={false}
                    canSearch={false}
                    datas={data}
                    onClickFn={showCategoryModal}
                  />
                ) : (
                  <LoadingAnimation />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CategoryDetails
            category={selectedCategory}
            categoryUpdated={categoryUpdated}
          />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            style={{ width: "50%" }}
            size="sm"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal show={showAdd} onHide={handleCloseAdd}>
        <Modal.Header>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCategory categoryUpdated={categoryUpdated} />
        </Modal.Body>
      </Modal>
      {/* <DelConfirm /> */}
    </DashboardLayout>
  );
}

export default Category;
