import React, { useState, useEffect } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from "components/MDBox";
import { Card, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Form from "react-bootstrap/Form";
import Rte from "components/BlogNote/Rte";
import { Button } from "react-bootstrap";
import CourseCategory from 'apis/courseCategory';
import Course from "apis/courses"
import CourseVideo from "apis/courseVideo"
import LoadingAnimation from 'components/Animation/LoadingAnimation';
import MDSnackbar from 'components/MDSnackbar';
import CourseFeature from 'apis/courseFeatures';
import { useNavigate, useParams } from 'react-router-dom';

const CreateBlog = () => {
    const params = useParams()
    const [titleEn, setTitleEN] = useState("");
    const [courses, setCourses] = useState([])
    const [coursesSelect, setCoursesSelect] = useState("");
    const [titleAr, setTitleAr] = useState("");
    const [level, setLevel] = useState("")
    const [title, setTitle] = useState();
    const [message, setMessage] = useState();
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(1);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [cover, setCover] = useState("");
    const [video, setVideo] = useState("");
    const navigate = useNavigate()
    useEffect(() => {
        const fetchDataCategory = async () => {
            try {
                const result = await Course.allCourses();
                setCourses(result?.data?.data?.docs);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching category data:", error);
            }
        };
        fetchDataCategory();
    }, [])
    useEffect(() => {
        if (params?.id) {
            setCoursesSelect(params?.id)
        }
    }, [params])
    const addCourseCategory = async () => {
        setLoading(true);
        setErrors(false);
        const formData = new FormData()
        formData.append("title", JSON.stringify({
            "en": titleEn,
            "ar": titleAr
        }),)
        formData.append("thumbnail", cover)
        formData.append("video", video)
        formData.append("course", coursesSelect)

        let data = {
            "title": JSON.stringify({
                "en": titleEn,
                "ar": titleAr
            }),
            "thumbnail": cover,
            "video": video,
            "course": coursesSelect

        };
        if (
            !titleEn ||
            !titleAr ||
            !cover ||
            !video ||
            !coursesSelect
        ) {
            setErrors(true);
            setLoading(false);
            window.scrollTo(0, 0);
        } else {
            try {
                if (cover) {
                    const res = await CourseVideo.addVideos(formData);
                    if(res.data){
                        setMessage("Video Added successfully")
                        setTimeout(() => {
                            if(!params?.id){
                                navigate("/coursevideo")
                            }else{
                                navigate("/viewcourses");
                            }  
                          }, 2000);
                        }

                }
                setLoading(false)
                setSuccessSB(true)
                clearData()
                setMessage("Course Video Added successfully")
                setTitle("success");
            } catch (err) {
                setLoading(false)
                setSuccessSB(true)
                setMessage("SomeThing Went Wrong")
                setTitle("error");
            }
        }
    };



    const clearData = () => {
        setTitleEN("");
        setTitleAr("");
        setCoursesSelect("")
    }
    const handleFileChange = (e) => {
        if (e.target.files) {
            setCover(e.target.files[0]);
        }
    };
    const handleVideoFileChange = (e) => {
        if (e.target.files) {
            setVideo(e.target.files[0]);
        }
    };



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <MDSnackbar
                color={title}
                icon="check"
                title={title}
                content={message}
                open={successSB}
                onClose={() => setSuccessSB(false)}
                close={() => setSuccessSB(false)}
            />
            {loading ? (
                <LoadingAnimation />
            ) : (
                <MDBox position="relative" mb={5}>
                    <Card
                        sx={{
                            position: "relative",
                            mt: 2,
                            // mx: 3,
                            py: 2,
                            px: 2,
                        }}
                    >
                        <Grid container spacing={6} alignItems="center">

                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title"
                                        value={titleEn}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleEN(e.target.value)}
                                    />
                                    {errors && (titleEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Name (AR)"
                                        value={titleAr}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleAr(e.target.value)}
                                    />
                                    {errors && (titleAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Thumbnail:
                                    </MDTypography>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control
                                            onChange={handleFileChange}
                                            type="file"
                                            placeholder="choose cover"
                                        />
                                    </Form.Group>
                                    {errors && (cover === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>

                            </Grid>
                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Video File:
                                    </MDTypography>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control
                                            onChange={handleVideoFileChange}
                                            type="file"
                                            accept="video/*"
                                            placeholder="choose cover"
                                        />
                                    </Form.Group>
                                    {errors && (cover === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>

                            </Grid>
                            {!params?.id &&
                                <Grid item lg={4} md={6} sm={12} width="100%" spacing={3}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Courses</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={coursesSelect}
                                                label="Skills"
                                                style={{ padding: "20px" }}
                                                onChange={(e) => setCoursesSelect(e.target.value)}
                                            >
                                                {courses.map((item) => (
                                                    <MenuItem value={item?._id}>{item?.title?.en}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors && (coursesSelect === "") && (
                                                <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                    Required!
                                                </MDTypography>
                                            )}
                                        </FormControl>
                                    </Box>
                                </Grid>}

                            <Grid item lg={4} md={4} />
                            <Grid item lg={4} md={4} sm={12} width="100%">
                                <MDBox mt={1}>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" onClick={addCourseCategory}>
                                            Save
                                        </Button>
                                    </div>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Card>
                </MDBox>
            )}
        </DashboardLayout>
    )
}

export default CreateBlog