import BaseURL from "./baseUrl";

// All apis for course categories
// const allVideos = (count, limit) => {
//     console.log("tryinf")
//     return BaseURL.get(`/course-skill/get?page=1&limit=10`);
// };

const addVideos = (data) => {
    return BaseURL.postForm(`/course-video/add`, data);
};

const deleteVideos = (id) => {
    return BaseURL.delete(`/course-video/delete/${id}`);
};

const getVideoById = (id) => {
    return BaseURL.get(`/course-video/get/${id}`);
}
const updateVideos = (id, data) => {
    return BaseURL.putForm(`/course-video/edit/${id}`, data);
};
const markAsWatched = (data) => {
    return BaseURL.postForm(`/course-video/mark-watched`, data);
};


const CourseVideos = {
    // allVideos,
    addVideos,
    deleteVideos,
    updateVideos,
    markAsWatched,
    getVideoById
};
export default CourseVideos