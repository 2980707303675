import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { baseURl } from "BaseUrl";

import Grid from "@mui/material/Grid";
import moment from "moment";
import { Typography, Card, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBadge from "components/MDBadge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import LoadingAnimation from "components/Animation/LoadingAnimation";

const Texts = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);

  //memoised data
  const memoizedData = useMemo(() => data, [data]);

  const userData = async () => {
    setLoad(true);
    try {
      const res = await axios.get(
        `${baseURl}/textGeneration/getGeneratedTexts?page=${count}`
      );
      const allUsers = res.data.data;
      setData([...data, ...allUsers]);
      setLoading(false);
      setLoad(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userData();
  }, [count]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !loading &&
        !load &&
        window.innerHeight + document.documentElement.scrollTop ===
          document.documentElement.offsetHeight
      ) {
        setCount((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, load]);

  return (
    <>
      {!loading ? (
        <DashboardLayout>
          <DashboardNavbar />
          {memoizedData?.map((items, index) => {
            return (
              <Card sx={{ mb: 5 }}>
                <MDBox py={2} px={2} lineHeight={1.25} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/profile/${items?.user?._id}`)}
                  >
                    <Box>
                      <MDAvatar
                        src={items?.user?.image}
                        alt="profile-image"
                        size="lg"
                        shadow="sm"
                      />
                    </Box>
                    <Grid container>
                      <Grid item xs={4}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {items?.user?.name}
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight={400}
                          color="text"
                        >
                          {items?.user?.email}
                          {items?.user?.email_verified_at ||
                          items?.user?.plan_start ? (
                            <Tooltip
                              title={
                                items?.user?.plan_start
                                  ? "subscriber"
                                  : "verified"
                              }
                            >
                              <MDBadge
                                badgeContent=<CheckCircleIcon />
                                color={
                                  items?.user?.plan_start ? "info" : "success"
                                }
                                variant="gradient"
                                size="sm"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="not verified">
                              <MDBadge
                                badgeContent=<CancelIcon />
                                color="error"
                                variant="gradient"
                                size="sm"
                              />
                            </Tooltip>
                          )}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" color="text" fontWeight="400">
                          {`Language : ${items?.language}`}
                        </Typography>
                        <Typography variant="h6" color="text" fontWeight="400">
                          {`Use Case : ${items?.useCase}`}
                        </Typography>
                        <MDTypography variant="button" color="text">
                          {moment(items?.createdAt).format(
                            "DD/MM/YYYY,h:mm:ss a"
                          )}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Box>
                </MDBox>
                <MDBox px={2}>
                  <Grid container spacing={0}>
                    {items?.text?.choices?.map((text, index) => {
                      return (
                        <Grid item xs={12}>
                          <DefaultProjectCard
                            description={`${index + 1} - ${text.text}`}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </MDBox>
              </Card>
            );
          })}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            {load && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-width="2"
                >
                  <path
                    stroke-dasharray="2 4"
                    stroke-dashoffset="6"
                    d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21"
                  >
                    <animate
                      attributeName="stroke-dashoffset"
                      dur="0.6s"
                      repeatCount="indefinite"
                      values="6;0"
                    />
                  </path>
                  <path
                    stroke-dasharray="30"
                    stroke-dashoffset="30"
                    d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="0.1s"
                      dur="0.3s"
                      values="30;0"
                    />
                  </path>
                  <path
                    stroke-dasharray="10"
                    stroke-dashoffset="10"
                    d="M12 8v7.5"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="0.5s"
                      dur="0.2s"
                      values="10;0"
                    />
                  </path>
                  <path
                    stroke-dasharray="6"
                    stroke-dashoffset="6"
                    d="M12 15.5l3.5 -3.5M12 15.5l-3.5 -3.5"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="0.7s"
                      dur="0.2s"
                      values="6;0"
                    />
                  </path>
                </g>
              </svg>
            )}
          </div>
        </DashboardLayout>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};

export default Texts;
