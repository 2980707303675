import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import { getAffilliate } from "apis/affilliate";

export default function affiliateUsers() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [sort, setSort] = useState(10);
  const [pageLimit, setPageLimit] = useState();
  const [search, setSearch] = useState(undefined);

  useEffect(() => {
    affiliateUsers();
  }, [refresh, count, limit, search, sort]);

  const affiliateUsers = async () => {
    try {
      setLoading(true);
      const res = await getAffilliate(count, limit, sort, search);
      const allAffiliates = res.data.data;
      setData(allAffiliates);
      const total = res.data.count;
      setTotal(total);
      const pLimit = Math.floor(total / limit + 1);
      setPageLimit(pLimit);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return {
    columns: [
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Affilliate code", accessor: "code", align: "left", width: "20%" },
      { Header: "Current earnings", accessor: "earning", align: "left" },
      { Header: "Total earning", accessor: "total_earning", align: "center" },
      { Header: "usage", accessor: "usage", align: "center" },
      { Header: "Create at", accessor: "created", align: "center" },
    ],

    rows: data?.map((item) => ({
      row: item,
      email: (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={item?.user?.image} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {item?.user.name}
            </MDTypography>
            <MDTypography variant="caption">{item?.user.email}</MDTypography>
          </MDBox>
        </MDBox>
      ),

      code: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item?.code}
        </MDTypography>
      ),
      earning: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          $ {item?.current_earning}
        </MDTypography>
      ),
      total_earning: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          $ {item?.total_earning}
        </MDTypography>
      ),
      usage: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item?.usage}
        </MDTypography>
      ),
      created: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(item?.createdAt).format("DD/MM/YYYY")}
        </MDTypography>
      ),
    })),
    data: data,
    loading: loading,
    search: search,
    setSearch: setSearch,
    refresh,
    setRefresh,
    setCount: setCount,
    pageLimit: pageLimit,
    limit: limit,
    setLimit: setLimit,
    count: count,
    total: total,
    sort: sort,
    setSort: setSort,
  };
}
