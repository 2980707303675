import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { baseURl } from "BaseUrl";
import moment from "moment";
import Enterprice from "apis/enterprice";
import MDAvatar from "components/MDAvatar";

export default function data() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [pageLimit, setPageLimit] = useState();
  const [search, setSearch] = useState();
  useEffect(() => {
    enterpriseData();
  }, [refresh, count, limit,search]);

  const enterpriseData = async (search) => {
    try {
      setLoading(true);
      const res = await axios.get( `${baseURl}/admin/enterprise?page=${count}&limit=${limit}&search=${search}`);
      // const res = await Enterprice.getAll();
      const allPlans = res.data.data;
      setData(allPlans);
      const total = res.data.count;
      setTotal(total)
      // setCount(total);
      const pLimit = Math.floor(total / limit + 1);
      setPageLimit(pLimit);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
   }

  // const enterpriseData = async () => {
  //   try {
  //     setLoading(true);
  //     // const res = await axios.get(`http://127.0.0.1:5050/admin/enterprise`);
  //     const res = await Enterprice.getAll();
  //     const allPlans = res.data.data;
  //     setData(allPlans);
  //     const total = res.data.count;
  //     setCount(total);
  //     const pLimit = Math.floor(total / limit + 1);
  //     setPageLimit(pLimit);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // planData();
  return {
    columns: [
      { Header: "NAME", accessor: "name", align: "left", width: "20%" },
      // { Header: "Data Allowance", accessor: "data", align: "center" },
      // { Header: "Category", accessor: "category", align: "center" },
      { Header: "Created At", accessor: "created", align: "center" },
      { Header: "Max Users", accessor: "max_users", align: "center" },
      { Header: "Team Members", accessor: "total_users", align: "center" },
      { Header: "Limit", accessor: "limit", align: "center" },
      
    ],

    rows: data?.map((item) => ({
      row: item,
      name: (
        // <MDTypography
        //   component="a"
        //   href="#"
        //   variant="caption"
        //   color="text"
        //   fontWeight="medium"
        // >
        //   {item?.name} <br/>
        //   {item?.owner.email}
        //   <MDAvatar src={item?.owner.image}  size="sm" />
        //   {/* {item?.owner.image} */}
        // </MDTypography>
        <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={item?.owner?.image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {item?.owner.name}
          </MDTypography>
          <MDTypography variant="caption">{item?.owner.email}</MDTypography>
        </MDBox>
      </MDBox>
      ),

    

      created: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(item?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
        </MDTypography>
      ),
      total_users: (
        <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {item?.users}
      </MDTypography>
      ),
      max_users: (
        <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {item?.max_users}
      </MDTypography>
      ),
      limit: (
        <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
          Text Limit: {item?.text_limit} <br/>
          Image Limit: {item?.image_limit}
        </MDTypography>
        
      ),
    })),
    data: data,
    loading: loading,
    search: search,
    setSearch: setSearch,
    refresh,
    setRefresh,
    setCount: setCount,
    pageLimit: pageLimit,
    limit: limit,
    setLimit: setLimit,
    count: count,
    total:total,
    
  };
}
