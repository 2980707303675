import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "axios";
import { baseURl } from "BaseUrl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import noUser from "assets/images/Aai-logo.png";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import { Toolbar, Tooltip } from "@mui/material";
import moment from "moment";

export const getData = async (count, search, limit) => {
  try {
    const res = await axios.get(
      `${baseURl}/user/admin/getAllUsers?page=${count}&limit=${limit}&subscribed_users=${true}&search=${search}`
    );
    const allUsers = res.data.users;
    const data = res.data;
    const total = res.data.total_count;

    return {
      users: allUsers,
      total,
      pageLimit: Math.floor(total / limit + 1),
      data: data,
    };
  } catch (err) {
    console.log(err);
  }
};

export default function data() {
  const Author = ({ image, name, email, status }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
      <MDBox ml={5}>{status}</MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [pageLimit, setPageLimit] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userData();
  }, [count, search, limit]);

  const userData = async () => {
    const { users, data, total, pageLimit } = await getData(
      count,
      search,
      limit
    );
    setData(data);
    setLoading(false);
    setUsers(users);
    setPageLimit(pageLimit);
  };

  useEffect(() => {
    userData();
  }, [count, search, limit]);

  return {
    columns: [
      { Header: "NAME", accessor: "author", align: "left", width: "20%" },
      { Header: "PLAN", accessor: "employed", align: "center" },
      { Header: "DAta left", accessor: "action", align: "center" },
      { Header: "USER CREATED AT", accessor: "created", align: "center" },
    ],

    rows: users?.map((user) => ({
      user: user,
      row: user,
      author: (
        <Author
          image={user?.image || noUser}
          name={user?.name || "Not available"}
          email={user?.email || user?.phone_number || "not available"}
          status=<MDBox ml={-1}>
            {user.email_verified_at || user?.plan_start ? (
              <Tooltip
                title={
                  user.enterprise && user.plan_start
                    ? "owner"
                    : user.enterprise && !user.plan_start
                    ? "team member"
                    : "subscriber"
                }
              >
                <MDBadge
                  badgeContent=<CheckCircleIcon />
                  color={
                    user.enterprise && user.plan_start
                      ? "primary"
                      : user.enterprise && !user.plan_start
                      ? "warning"
                      : "info"
                  }
                  variant="gradient"
                  size="sm"
                />
              </Tooltip>
            ) : (
              <Toolbar title="not verified">
                <MDBadge
                  badgeContent=<CancelIcon />
                  color="error"
                  variant="gradient"
                  size="sm"
                />
              </Toolbar>
            )}
          </MDBox>
        />
      ),

      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {user?.plan}
        </MDTypography>
      ),
      action: (
        <Job
          title={`Text : ${user?.text_count_left}`}
          description={`Image: ${user?.image_count_left}`}
        />
      ),
      created: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(user?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
        </MDTypography>
      ),
    })),
    data: data,
    loading: loading,
    search: search,
    setSearch: setSearch,
    pageLimit: pageLimit,
    limit: limit,
    setLimit: setLimit,
    count: count,
    setCount: setCount,
    loading: loading,
  };
}
