import { useMemo, useState, useRef, useEffect } from "react";

import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDButton from "components/MDButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button, IconButton, Input, InputAdornment, Stack, TextField, Typography } from "@mui/material";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  search,
  setSearch,
  setLimit,
  limit,
  datas,
  total,
  type,
  setCount,
  count,
  onClickFn,
  sort,
  setSort,
}) {
  const ref = useRef(null);
  const columns = useMemo(() => table.columns, [table]);
  const [searchText, setSearchText] = useState("");
  const data = useMemo(() => table.rows, [table]);
  const [sorted, setSorted] = useState(false);
  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, useSortBy, usePagination);

  const {
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    state: { pageIndex },
  } = tableInstance;

  const renderPagination = pageOptions.map((option) => (
    <MDPagination item key={option} onClick={() => gotoPage(Number(option))} active={pageIndex === option}>
      {option + 1}
    </MDPagination>
  ));

  const handleNext = () => {
    if (count < Math.ceil(datas.total_count ? datas.total_count : total / 10)) setCount(count + 1);
  };

  const handlePrev = () => {
    if (count >= 2) {
      setCount(count - 1);
    } else setCount(1);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setLimit(value);
  };

  const handleSorting = (column) => {
    if (sort) {
      if (sorted) {
        setSorted(!sorted);
        switch (column.id) {
          case "email":
            setSort(1);
            break;
          case "code":
            setSort(3);
            break;
          case "earning":
            setSort(5);
            break;
          case "total_earning":
            setSort(7);
            break;
          case "created":
            setSort(9);
            break;
        }
      } else {
        setSorted(!sorted);
        switch (column.id) {
          case "email":
            setSort(2);
            break;
          case "code":
            setSort(4);
            break;
          case "earning":
            setSort(6);
            break;
          case "total_earning":
            setSort(8);
            break;
          case "created":
            setSort(10);
            break;
        }
      }
    }
  };

  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  const handleSearch = () => {
    setSearch(searchText);
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {canSearch && (
            <MDBox ml="auto" sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search..."
                variant="outlined"
                value={searchText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ paddingRight: 0 }} position="end">
                      {searchText && (
                        <Button
                          onClick={() => {
                            setSearch("");
                            setSearchText("");
                          }}
                          sx={{ padding: 0, minWidth: "35px" }}
                        >
                          <CloseRoundedIcon sx={{ paddingRight: 0 }} fontSize="medium" />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton onClick={handleSearch}>
                <SearchRoundedIcon color="secondary" fontSize="large" sx={{ cursor: "pointer", padding: "5px" }} />
              </IconButton>
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table>
        {rows.length === 0 && (
          <Typography variant="h6" align="center">
            no user found
          </Typography>
        )}
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                  onClick={() => handleSorting(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  type === "BLOG" ? null : onClickFn ? onClickFn(row.original.row) : console.log("onclick not specified");
                }}
              >
                {row.cells.map((cell) => (
                  <DataTableBodyCell noBorder={noEndBorder && rows.length - 1 === key} align={cell.column.align ? cell.column.align : "left"} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {pagination && (
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          <MDPagination variant={pagination.variant ? pagination.variant : "gradient"} color={pagination.color ? pagination.color : "info"}>
            <MDPagination item onClick={handlePrev}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
            <MDBox mb={{ xs: 3, sm: 0 }} mx={3}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Showing {count} of {type === "BLOG" ? total : Math.ceil(datas.total_count ? datas.total_count : total / 10)}
              </MDTypography>
            </MDBox>
            <MDPagination item onClick={handleNext}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          </MDPagination>
        </MDBox>
      )}
    </TableContainer>
  );
}

export default DataTable;
