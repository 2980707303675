import React, { useState, useEffect } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Form from "react-bootstrap/Form";
import Rte from "components/BlogNote/Rte";
import { Button } from "react-bootstrap";
// import logo from "../../../assets/images/Aai-logo.png";
import LoadingAnimation from 'components/Animation/LoadingAnimation';
import MDSnackbar from 'components/MDSnackbar';
import { useNavigate, useParams } from 'react-router-dom';
// apis
import Create from "apis/courses"
import CourseCategory from 'apis/courseCategory';
import CourseInstructor from 'apis/courseInstructor';
import CourseSkills from 'apis/courseSkills';
import CourseFeature from 'apis/courseFeatures';
import moment from 'moment';
//dropdowm
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Multiselect from 'multiselect-react-dropdown';
import StepsForm from "./addInput"
const CreateCourse = () => {
    const params = useParams();
    const [courseData, setCourseData] = useState()
    const [titleEn, setTitleEN] = useState("");
    const [titleAr, setTitleAr] = useState("");
    //dropdowns
    const [category, setCategory] = useState([]);
    const [categorySelect, setCategorySelect] = useState("");
    const [instructor, setInstructor] = useState([]);
    const [instructorSelect, setInstructorSelect] = useState("");
    const [skills, setSkill] = useState([]);
    const [features, setFeatures] = useState([]);
    const [outcomeAr, setOutcomeAr] = useState("");
    const [outcomeEn, setOutcomeEn] = useState("");
    const [stepsAr, setStepsAr] = useState("");
    const [stepsEn, setStepsEn] = useState("");
    const [steps, setSteps] = useState(['']);
    const [outcomeEng, setOutcomeEng] = useState(['']);
    const [outcomeArab, setOutcomeArab] = useState(['']);
    const [stepsArabic, setStepsArabic] = useState(['']);
    const [experienceAr, setExperienceAr] = useState("");
    const [experienceEn, setExperienceEn] = useState("");
    const [shortDescriptionEn, setShortDescriptionEn] = useState("");
    const [shortDescriptionAr, setShortDescriptionAr] = useState("");
    const [price, setPrice] = useState("");
    const [cover, setCover] = useState("");
    const [whatYouLearnEn, setWhatYouLearnEn] = useState("");
    const [whatYouLearnAr, setWhatYouLearnAr] = useState("");
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [message, setMessage] = useState();
    const [title, setTitle] = useState();
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(1);
    const [selectedValues, setSelectedValues] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedValuesSkill, setSelectedValuesSkill] = useState([]);
    const [optionsSkill, setOptionsSkill] = useState([]);
    const [publishedName, setPublishedName] = useState("")
    const [whatLearn, setWhatLearn] = useState(['']);
    const [whatLearnAr, setWhatLearnAr] = useState(['']);
    const date = moment(new Date()).format('YYYY-MM-DD');
    const publish = [{ name: "Publish", value: date, id: 1 }, { name: "Unpublish", value: null, id: 2 }]
    const [publishedAt, setPublishedAt] = useState(null)
    const [changeImage, setChangeImage] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await CourseCategory.allCategory(count, limit);
                setCategory(result?.data?.data?.docs);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching category data:", error);
            }
        };
        const fetchDataInstructor = async () => {
            try {
                const result = await CourseInstructor.allInstructor(count, limit);
                setInstructor(result?.data?.data?.docs);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching category data:", error);
            }
        };
        const fetchDataSkills = async () => {
            try {
                const result = await CourseSkills.allSkills(count, limit);
                setSkill(result?.data?.data?.docs);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching category data:", error);
            }
        };
        const fetchDataFeature = async () => {
            try {
                const result = await CourseFeature.allFeature(count, limit);
                setFeatures(result?.data?.data?.docs);
            } catch (error) {
                // Handle any errors here
                console.error("Error fetching category data:", error);
            }
        };
        fetchData();
        fetchDataInstructor();
        fetchDataSkills();
        fetchDataFeature();
    }, []);
    useEffect(() => {
        // Map the data to create the options array and set it in the state
        const mappedOptions = features.map(item => ({
            id: item._id,
            name: item.name.en
        }));
        setOptions(mappedOptions);
    }, [features]);
    useEffect(() => {
        // Map the data to create the options array and set it in the state
        const mappedOptions = skills.map(item => ({
            id: item._id,
            name: item.name.en
        }));
        setOptionsSkill(mappedOptions);
    }, [skills]);
    useEffect(() => {
        if (courseData) {
            setTitleEN(courseData?.title.en || "")
            setTitleAr(courseData?.title.ar || "")
            setCover(courseData?.coverImage || "")
            setPrice(courseData?.price)
            if (courseData?.whatYouWillLearn) {
                setWhatLearn(courseData?.whatYouWillLearn?.map((item) => item?.description?.en));
                setWhatLearnAr(courseData?.whatYouWillLearn?.map((item) => item?.description?.ar));
            }

            if (courseData?.steps?.list) {
                setSteps(courseData?.steps?.list.map((item) => item?.step?.en));
                setStepsArabic(courseData?.steps?.list.map((item) => item?.step?.ar));
            }
            if (courseData?.outcomes?.list) {
                setOutcomeEng(courseData?.outcomes?.list.map((item) => item?.outcome?.en));
                setOutcomeArab(courseData?.outcomes?.list.map((item) => item?.outcome?.ar));
            }
            setStepsEn(courseData?.title.en)
            setStepsAr(courseData?.title.ar)
            setExperienceEn(courseData?.requiredExperince[0].experince.en)
            setExperienceAr(courseData?.requiredExperince[0].experince.ar)
            setOutcomeEn(courseData?.outcomes?.title?.en)
            setOutcomeAr(courseData?.outcomes?.title?.ar)
            if (courseData?.features[0]?.feature?._id) {
                const transformedData = courseData?.features.map(item => ({
                    id: item._id,
                    name: item.feature.name.en
                }));
                const resultArray = [];

                for (const item1 of transformedData) {
                    for (const item2 of options) {
                        if (item1.name === item2.name) {
                            resultArray.push({ id: item2.id, name: item2.name });
                        }
                    }
                }
                setSelectedValues(resultArray)
            }
            if (courseData?.skills[0]?.skill?._id) {
                const transformedData = courseData?.skills.map(item => ({
                    id: item._id,
                    name: item.skill.name.en
                }));
                const resultArray = [];

                for (const item1 of transformedData) {
                    for (const item2 of optionsSkill) {
                        if (item1.name === item2.name) {
                            resultArray.push({ id: item2.id, name: item2.name });
                        }
                    }
                }
                setSelectedValuesSkill(resultArray)
            }

            setInstructorSelect(courseData?.instructors[0]?._id || "")
            setCategorySelect(courseData?.category?._id || "")
            setShortDescriptionEn(courseData?.description?.en || "")
            setShortDescriptionAr(courseData?.description?.ar || "")
        }
    }, [courseData])
    useEffect(() => {
        if (params?.id && !courseData) {
            fetchCourse(params?.id)
        }
    }, [params])
    const fetchCourse = async (id) => {

        try {
            const res = await Create.getCourseById(id);
            setLoading(false);
            setCourseData(res?.data?.data)
        } catch (err) {
            setLoading(false);
            setSuccessSB(true)
            setMessage("SomeThing Went Wrong")
            setTitle("error");
        }
    }
    console.log(category, "category")
    const addCourse = async () => {
        setLoading(true);
        setErrors(false);
        const formData = new FormData();
        formData.append("title", JSON.stringify({ en: titleEn, ar: titleAr }));
        formData.append("file", cover)
        formData.append("instructors", JSON.stringify([instructorSelect]))
        formData.append("steps", JSON.stringify({
            "title": { "en": stepsEn, "ar": stepsAr }, "list": steps.filter((item) => item !== '').map((item, index) => ({
                "step": {
                    "en": item,
                    "ar": stepsArabic[index] || "",
                },
            }))
        }))
        formData.append("whatYouWillLearn", JSON.stringify(
            whatLearn.filter((item) => item !== '').map((item, index) => ({
                "description": { "en": item, "ar": whatLearnAr[index] }
            }))
        ))
        formData.append("skills", JSON.stringify(selectedValuesSkill.map((item) => ({ "skill": item.id }))))
        formData.append("outcomes", JSON.stringify({
            "title": { "en": outcomeEn, "ar": outcomeAr }, "list":
                outcomeEng.filter((item) => item !== '').map((item, index) => ({
                    "outcome": {
                        "en": item,
                        "ar": outcomeArab[index] || "",
                    },
                }))
        }))
        formData.append("description", JSON.stringify({ "en": shortDescriptionEn, "ar": shortDescriptionAr }))
        formData.append("requiredExperince", JSON.stringify([{ "experince": { "en": experienceEn, "ar": experienceAr } }]))
        formData.append("skills", JSON.stringify(selectedValuesSkill.map((item) => ({ "skill": item.id }))))
        formData.append("category", categorySelect)
        formData.append("features", JSON.stringify(selectedValues.map((item) => ({ "feature": item.id }))))
        formData.append("price", price || "")
        formData.append("publishedAt", publishedAt || null)
        if (!titleEn || !titleAr || !cover || !whatLearn || !whatLearnAr || !outcomeEn || !outcomeAr || !stepsEn || !stepsAr || !experienceEn || !experienceAr || !price || !instructorSelect || !categorySelect) {
            setErrors(true);
            setLoading(false);
            window.scrollTo(0, 0);
        } else {
            try {
                const res = await Create.updateCourse(params?.id, formData);
                setLoading(false)
                setSuccessSB(true)
                if(res.data){
                    setMessage("Course Updated successfully")
                    setTimeout(() => {
                        navigate("/viewcourses");
                      }, 2000);
                    }
                // clearData()
                setTitle("success");
            } catch (err) {
                setLoading(false)
                setSuccessSB(true)
                setMessage("SomeThing Went Wrong")
                setTitle("error");
            }
        }
    };
    const onSelectSkill = (selectedList, selectedItem) => {
        setSelectedValuesSkill(selectedList);
    };

    const onRemoveSkill = (selectedList, removedItem) => {
        setSelectedValuesSkill(selectedList);
    };
    const onSelect = (selectedList, selectedItem) => {
        setSelectedValues(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setSelectedValues(selectedList);
    };
    const handleFileChange = (e) => {
        if (e.target.files) {
            setCover(e.target.files[0]);
        }
    };
    const handleFileChangeBanner = (e) => {
        if (e.target.files) {
            setBanner(e.target.files[0]);
        }
    };
    console.log(courseData, "courseData")
    console.log(selectedValuesSkill, "selectedValuesSkill")
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <MDSnackbar
                color={title}
                icon="check"
                title={title}
                content={message}
                open={successSB}
                onClose={() => setSuccessSB(false)}
                close={() => setSuccessSB(false)}
            />
            {loading ? (
                <LoadingAnimation />
            ) : (
                <MDBox position="relative" mb={5}>
                    <Card
                        sx={{
                            position: "relative",
                            mt: 2,
                            // mx: 3,
                            py: 2,
                            px: 2,
                        }}
                    >
                        <Grid container spacing={6} alignItems="center">

                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox>
                                    <MDTypography variant="button" fontWeight="bold" color="text" fontSize="20px">
                                        Main Details:
                                    </MDTypography>
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title"
                                        value={titleEn}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleEN(e.target.value)}
                                    />
                                    {errors && (titleEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Name (AR)"
                                        value={titleAr}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleAr(e.target.value)}
                                    />
                                    {errors && (titleAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    {changeImage ?
                                        <MDBox height="100%" mt={0.5} lineHeight={1} >
                                            <MDTypography variant="button" fontWeight="regular" color="text">
                                                Cover Image(400 x 284):
                                            </MDTypography>

                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control
                                                    onChange={handleFileChange}
                                                    type="file"
                                                    accept="image/*" 
                                                    placeholder="choose cover"
                                                />
                                            </Form.Group>
                                            {errors && (cover === "") && (
                                                <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                    Required!
                                                </MDTypography>
                                            )}
                                        </MDBox> : <MDBox mt={1}>
                                            <div className="d-flex gap-2" style={{ alignItems: "center" }} >
                                                <img src={courseData?.coverImage} style={{ objectFit: "contain", borderRadius: "20px", width: "50px" }} />
                                                <Button style={{ height: "50px" }} variant="primary" onClick={() => setChangeImage(true)}>
                                                    Change Image
                                                </Button>
                                            </div>
                                        </MDBox>}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Price:
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Type price"
                                        value={price}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                    {errors && (price === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Select Instructor:
                                    </MDTypography>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <FormControl fullWidth required>
                                            <InputLabel id="demo-simple-select-label">Instructor</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={instructorSelect}
                                                label="Instructor"
                                                style={{ padding: "20px" }}
                                                onChange={(e) => setInstructorSelect(e.target.value)}
                                            >
                                                {instructor.map((item) => (
                                                    <MenuItem value={item?._id}>{item?.name?.en}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors && (instructorSelect === "") && (
                                                <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                    Required!
                                                </MDTypography>
                                            )}
                                        </FormControl>
                                    </MDBox>
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Select Category:
                                    </MDTypography>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={categorySelect}
                                                label="Category"
                                                style={{ padding: "20px" }}
                                                onChange={(e) => { setCategorySelect(e.target.value); }}
                                            > {category.map((item) => (
                                                <MenuItem value={item?._id}>{item?.name?.en}({item?.level})</MenuItem>
                                            ))}

                                            </Select>
                                            {errors && (categorySelect === "") && (
                                                <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                    Required!
                                                </MDTypography>
                                            )}
                                        </FormControl>
                                    </Box>
                                </MDBox>

                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Select Features:
                                    </MDTypography>
                                    <Multiselect
                                        options={options}
                                        selectedValues={selectedValues}
                                        onSelect={onSelect}
                                        onRemove={onRemove}
                                        displayValue="name"
                                    />
                                    {errors && (selectedValues.length === 0) && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>

                                <Box sx={{ minWidth: 120, marginTop: "16px" }}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">Publish</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={publishedName}
                                            label="Publish"
                                            style={{ padding: "20px" }}
                                            onChange={(e) => setPublishedName(e.target.value)}
                                        >
                                            {publish.map((item) => (
                                                <MenuItem key={item._id} value={item.name} onClick={() => setPublishedAt(item.value)}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox>
                                    <MDTypography variant="button" fontWeight="bold" color="text" fontSize="20px">Project Details Section</MDTypography>
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Steps title (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title (EN)"
                                        value={stepsEn}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setStepsEn(e.target.value)}
                                    />
                                    {errors && (titleEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Steps title(ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title (AR)"
                                        value={stepsAr}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setStepsAr(e.target.value)}
                                    />
                                    {errors && (stepsAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Steps list (en):
                                    </MDTypography>
                                    <StepsForm steps={steps} setSteps={setSteps} />
                                    {errors && (steps[0] === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Steps list (ar):
                                    </MDTypography>
                                    <StepsForm steps={stepsArabic} setSteps={setStepsArabic} />
                                    {errors && (stepsArabic[0] === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Required Experience (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title"
                                        value={experienceEn}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setExperienceEn(e.target.value)}
                                    />
                                    {errors && (titleEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Required Experience (ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Name (AR)"
                                        value={experienceAr}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setExperienceAr(e.target.value)}
                                    />
                                    {errors && (titleAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Enter Description (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Enter short description"
                                        value={shortDescriptionEn}
                                        rows={7}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setShortDescriptionEn(e.target.value)}
                                    />
                                    {errors && (shortDescriptionEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Enter Description (ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Enter short description"
                                        value={shortDescriptionAr}
                                        rows={7}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setShortDescriptionAr(e.target.value)}
                                    />
                                    {errors && (stepsAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} width="100%" direction="column">
                                <MDBox>
                                    <MDTypography variant="button" fontWeight="bold" color="text" fontSize="20px">About Section</MDTypography>
                                </MDBox>
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                    Select Skills:
                                </MDTypography>
                                <Multiselect
                                    options={optionsSkill}
                                    selectedValues={selectedValuesSkill}
                                    onSelect={onSelectSkill}
                                    onRemove={onRemoveSkill}
                                    displayValue="name"
                                />
                                {errors && (selectedValues.length === 0) && (
                                    <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                        Required!
                                    </MDTypography>
                                )}
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        What you will learn (en):
                                    </MDTypography>
                                    <StepsForm steps={whatLearn} setSteps={setWhatLearn} />
                                    {errors && (whatLearn[0] === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        What you will learn (ar):
                                    </MDTypography>
                                    <StepsForm steps={whatLearnAr} setSteps={setWhatLearnAr} />
                                    {errors && (whatLearnAr[0] === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} width="100%">

                            </Grid>

                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox>
                                    <MDTypography variant="button" fontWeight="bold" color="text" fontSize="20px">Outcome Section</MDTypography>
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Outcome title (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title"
                                        value={outcomeEn}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setOutcomeEn(e.target.value)}
                                    />
                                    {errors && (titleEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Outcome title (ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Name (AR)"
                                        value={outcomeAr}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setOutcomeAr(e.target.value)}
                                    />
                                    {errors && (titleAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Outcome list (en):
                                    </MDTypography>
                                    <StepsForm steps={outcomeEng} setSteps={setOutcomeEng} />
                                    {errors && (outcomeEng[0] === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Outcome list (ar):
                                    </MDTypography>
                                    <StepsForm steps={outcomeArab} setSteps={setOutcomeArab} />
                                    {errors && (outcomeArab[0] === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid>
                            <Grid item lg={4} md={4} />
                            <Grid item lg={4} md={4} sm={12} width="100%">
                                <MDBox mt={1}>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" onClick={addCourse}>
                                            Save
                                        </Button>
                                    </div>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Card>
                </MDBox>
            )}
        </DashboardLayout>
    )
}

export default CreateCourse