import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import CourseTable from "./data/CourseTable"
// import BlogTable from "../../Blog/data/BlogTable";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";


function Blog() {
    var { columns, rows, data, loading, alert, setAlert, message, title, refresh, setRefresh, pageLimit, limit, setLimit, count, setCount, total } = CourseTable();
    const navigate = useNavigate();

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={2}
                                py={1.5}
                                px={1.5}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"

                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <MDTypography variant="h5" color="white">
                                    Courses
                                </MDTypography>
                                <Button
                                    onClick={() => {
                                        navigate(`/add-course`)
                                    }}
                                    variant="success"
                                >
                                    Create
                                </Button>
                            </MDBox>
                            <MDBox>
                                {!loading ? (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={true}
                                        entriesPerPage={true}
                                        showTotalEntries={true}
                                        noEndBorder
                                        pagination={true}
                                        pageLimit={pageLimit}
                                        limit={limit}
                                        setLimit={setLimit}
                                        canSearch={false}
                                        datas={data}
                                        total={total}
                                        count={count}
                                        setCount={setCount}
                                    />
                                ) : (
                                    <LoadingAnimation />
                                )}
                                {alert && (
                                    <MDSnackbar
                                        color={title}
                                        icon="check"
                                        title={title}
                                        content={message}
                                        open={alert}
                                        onClose={() => setAlert(false)}
                                        close={() => setAlert(false)}
                                    />
                                )}
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Blog;
