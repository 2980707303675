import BaseURL from "./baseUrl";
// All apis for course categories
const allFeature = (count, limit) => {
    return BaseURL.get(`/course-feature/get?page=1&limit=10`);
};

const addFeature = (data) => {
    return BaseURL.postForm(`/course-feature/add`, data);
};


const deleteFeature = (id) => {
    return BaseURL.delete(`/course-feature/delete/${id}`);
};

// const getBlogById = (id) => {
//     return BaseURL.get(`/admin/blog/${id}`);
// }


const updateFeature = (id, data) => {
    return BaseURL.putForm(`/course-feature/edit/${id}`, data);
};

const CourseFeature = {
    allFeature,
    addFeature,
    deleteFeature,
    updateFeature
};
export default CourseFeature