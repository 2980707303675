import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import { getAffilliateById } from "apis/affilliate";
import { useParams } from "react-router-dom";
import { getAffilliateByCode } from "apis/affilliate";

export default function affilliatedUsers() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [codeData, setCodeData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [pageLimit, setPageLimit] = useState();
  const [search, setSearch] = useState();
  const [showPayment, setShowPayment] = useState(false);

  useEffect(() => {
    if (showPayment) {
      paymentRequest();
    } else {
      affiliateUsers();
    }
  }, [refresh, count, limit, search, params.id, showPayment]);

  const affiliateUsers = async () => {
    try {
      setLoading(true);
      const res = await getAffilliateById(count, limit, params.id);
      setCodeData(res.data.code);
      const allAffiliates = res.data.data;
      setData(allAffiliates);
      const total = res.data.data.totalPages;
      setTotal(total);
      // const pLimit = Math.floor(total / limit + 1);
      // setPageLimit(pLimit);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const paymentRequest = async () => {
    try {
      setLoading(true);
      const res = await getAffilliateByCode(pageLimit, limit, params.id);
      setCodeData(res.data.code);
      const allAffiliates = res.data.data;

      setData(allAffiliates);
      const total = res.data.data.totalPages;
      setTotal(total);
      const pLimit = Math.floor(total / limit + 1);
      setPageLimit(pLimit);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return {
    columns: [
      { Header: "User", accessor: "user", align: "left" },
      { Header: "Create at", accessor: "created", align: "center" },
      { Header: "usage", accessor: "usage", align: "center" },
      ...(showPayment ? [{ Header: "earning", accessor: "earning", align: "center" }] : []),
      ...(showPayment ? [{ Header: "payment Gate", accessor: "payment", align: "center" }] : []),
      ...(showPayment ? [{ Header: "incoive_id", accessor: "payment_id", align: "center" }] : []),
      ...(showPayment ? [{ Header: "Percent Off", accessor: "per_off", align: "center" }] : []),
      ...(showPayment ? [{ Header: "Total Amount", accessor: "total", align: "center" }] : []),
      ...(showPayment ? [{ Header: "Usage Type", accessor: "type", align: "center" }] : []),
    ],

    rows: data?.docs?.map((item) => ({
      row: item,
      user: (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={item?.used_by?.image} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {item?.used_by?.name}
            </MDTypography>
            <MDTypography variant="caption">{item?.used_by?.email}</MDTypography>
          </MDBox>
        </MDBox>
      ),
      created: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(item?.createdAt).format("DD/MM/YYYY")}
        </MDTypography>
      ),

      usage: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item?.usage_type}
        </MDTypography>
      ),

      ...(showPayment
        ? {
            earning: (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                $ {item?.earning}
              </MDTypography>
            ),
          }
        : {}),
      ...(showPayment
        ? {
            payment: (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {item?.paymentGate}
              </MDTypography>
            ),
          }
        : {}),
      ...(showPayment
        ? {
            payment_id: (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {item?.payment_gate_invoice_id}
              </MDTypography>
            ),
          }
        : {}),
      ...(showPayment
        ? {
            per_off: (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {item?.percent_off}
              </MDTypography>
            ),
          }
        : {}),
      ...(showPayment
        ? {
            total: (
              <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                {item?.total_amount}
              </MDTypography>
            ),
          }
        : {}),
    })),
    data: data,
    loading: loading,
    search: search,
    setSearch: setSearch,
    refresh,
    setRefresh,
    setCount: setCount,
    pageLimit: pageLimit,
    limit: limit,
    setLimit: setLimit,
    count: count,
    total: total,
    codeData: codeData,
    setShowPayment,
    showPayment,
  };
}
