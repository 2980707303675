import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import axios from "axios";
import { baseURl } from "BaseUrl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import noUser from "assets/images/Aai-logo.png";
import { Toolbar, Tooltip } from "@mui/material";
import moment from "moment";

export default function data() {
  const DataAllowance = ({ image, text }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {image}
      </MDTypography>
      <MDTypography variant="caption">{text}</MDTypography>
    </MDBox>
  );

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageLimit, setPageLimit] = useState();
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState();

  useEffect(() => {
    planData();
  }, [refresh, count, limit ]);

  const planData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseURl}/survey?page=${count}&limit=${limit}`);
      const allPlans = res.data.data;
      setData(allPlans);
      setPageLimit(Math.floor(res?.data?.count / limit + 1));
      setTotal(res?.data?.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // planData();
  return {
    columns: [
      { Header: "Email", accessor: "email", align: "left", width: "20%" },
      { Header: "Question", accessor: "question", align: "left" },
      { Header: "Duration", accessor: "duration", align: "left" },
      { Header: "Answer", accessor: "answer", align: "left" },
      { Header: "Created At", accessor: "created", align: "center" },
    ],

    rows: data?.map((plan) => ({
      row: plan,
      email: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {plan?.userDetails?.email}
        </MDTypography>
      ),

      question: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {plan?.questionsAndAnswers.map((questions) => (
            <>
              <span>{questions.question}</span>
              <br></br>
            </>
          ))}
        </MDTypography>
      ),

      duration: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {plan?.questionsAndAnswers.map((questions) => (
            <>
              <span>{questions.duration}</span>
              <br></br>
            </>
          ))}
        </MDTypography>
      ),

      answer: (
        <MDTypography component="a" href="#" variant="caption" color="center" fontWeight="medium">
          {plan?.questionsAndAnswers.map((questions) => (
            <>
              <span>{questions.answer ?? '-'}</span>
              <br></br>
            </>
          ))}
        </MDTypography>
      ),

      data: (
        <DataAllowance
          text={`Text : ${plan?.metadata?.text_count_left ? plan?.metadata?.text_count_left : 0}`}
          image={`Image: ${plan?.metadata?.image_count_left ? plan?.metadata?.image_count_left : 0}`}
        />
      ),

      created: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(plan?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
        </MDTypography>
      ),
    })),
    data: data,
    loading: loading,
    refresh,
    setRefresh,
    alert,
    pageLimit: pageLimit,
    limit: limit,
    setLimit: setLimit,
    count: count,
    setCount: setCount,
    total: total
  };
}
