import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "components/BlogNote/Rte.css";

const Rte = ({ content, setContent }) => {
  const HandleEditorChange = (data) => {
    setContent(data);
  };
  return (
    <Editor
      apiKey="r6zsgmbzyet8keohi5adg8adr13o0thjtfgptn25hq0oapoa"
      value={content}
      init={{
        branding: false,
        height: 1000,
        menubar: false,
        plugins:
          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tableofcontents footnotes mergetags autocorrect typography inlinecss markdown",
        toolbar:
          "forecolor fontweight backcolor | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
      }}
      onEditorChange={HandleEditorChange}
    />
  );
};

export default Rte;
