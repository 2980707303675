import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseURl } from "BaseUrl";
import axios from "axios";
import moment from "moment";

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import aesjs from "aes-js";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsBarChartSubsandRenews from "examples/Charts/BarCharts/ReportsBarChartSubsandRenews";
import ReportsBarChartNewSubs from "examples/Charts/BarCharts/ReportsBarChartNewSubs";
import ReportsBarChartWebsiteSub from "examples/Charts/BarCharts/ReportsBarChartWebsiteSub";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import CountUp from "react-countup";

import { Box, Card, Skeleton } from "@mui/material";
import TableCell from "@mui/material/TableCell";

import ReportsBarChartS from "examples/Charts/BarCharts/ReportsBarChart copy";
import ReportsBarChartCombined from "examples/Charts/BarCharts/ReportsBarChartCombined";
import ReportsBarChartSubscriptionbyMethod from "examples/Charts/BarCharts/ReportsBarChartSubscriptionbyMethod";
import ReportsBarChartSubscriptionbyPlatform from "examples/Charts/BarCharts/ReportsBarChartSubscriptionbyPlatform";
import ReportsBarChartArabyGpt from "examples/Charts/BarCharts/ReportsBarChartArabyGpt";
import ReportsBarChartConversion from "examples/Charts/BarCharts/ReportsBarChartConversion";
import ReportsBarChartAudioVideo from "examples/Charts/BarCharts/ReportsBarChartAudioVideo";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import ReportBarChartDocument from "examples/Charts/BarCharts/ReportsBarChartDocument";
import ReportsBarPdfChat from "examples/Charts/BarCharts/ReportsBarPdfChat";
import ReportsBarHTML from "examples/Charts/BarCharts/ReportsBarHTML";

function Dashboard() {
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [StatLoading, setStatLoading] = useState(true);
  const [cardData, setCardData] = useState("");
  const [data, setData] = useState("");
  const [graph, setGraph] = useState([]);
  const [dgraph, setDgraph] = useState([]);
  const [numbers, setNumbers] = useState({
    user: "",
    subs: "",
    image: "",
    texts: "",
  });
  const [year, setYear] = useState(2024);
  const [month, setMonth] = useState();
  const [search, setSearch] = useState("");
  const [cases, setCase] = useState([]);
  const [business_cases, setbusiness_cases] = useState("");
  const [flag, setFlag] = useState(true);
  const [totalFlag, setTotalFlag] = useState(true);
  const [monthOption, setMonthOption] = useState([]);
  const [yearOption, setYearOption] = useState();
  let yearly = "";
  let monthly = "";
  let monthdate = "";
  let yearoptiondata;

  const decode = (hex) => {
    var key = process.env.REACT_APP_DECODE_KEY;
    var keyBytes = aesjs.utils.utf8.toBytes(key);
    var encryptedBytes = aesjs.utils.hex.toBytes(hex);
    var aesCtr = new aesjs.ModeOfOperation.ctr(keyBytes, new aesjs.Counter(5));
    var decryptedBytes = aesCtr.decrypt(encryptedBytes);
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
  };
  //Api for daily data
  const changeToDailyData = async () => {
    setLoading(true);
    axios
      .get(`${baseURl}/admin/daily_statistics`)
      .then((response) => {
        setDgraph(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const changeToggle = () => {
    setTotalFlag(!totalFlag);
  };
  const dailyData = async () => {
    setLoading(true);

    //Api for year data
    //Flag is for switching between year and month

    if (flag) {
      yearly = "yearly";
      axios
        .get(`${baseURl}/new-stats/overview?year=${year}&type=${flag ? "year" : "month"}`)
        .then((response) => {
          setGraph(response.data.chartsData);
          setCardData(response.data);
          let arr = [...response?.data?.useCaseData?.code_use_cases, ...response?.data?.useCaseData?.images_use_cases, ...response?.data?.useCaseData?.text_use_cases];
          let arr1 = arr.sort((a, b) => b.count - a.count);
          setCase(arr1);
          // To only view months with data in the UI

          // setMonthOption(response.data.data?.map((row) => moment(row._id).format("MM")));

          // To only view years with data in the UI
          yearoptiondata = response?.data?.chartsData.map((row) => moment(row._id).format("YYYY"));
          // Too remove duplicate values
          yearoptiondata = [...new Set(yearoptiondata)];
          setYearOption(yearoptiondata);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      //Api for month data
      monthly = "monthly";
      axios
        .get(`${baseURl}/new-stats/overview?year=${year}&month=${month}&type=month`)
        .then((response) => {
          setCardData(response.data);
          setGraph(response.data.chartsData);
          let arr = [...response?.data?.useCaseData?.code_use_cases, ...response?.data?.useCaseData?.images_use_cases, ...response?.data?.useCaseData?.text_use_cases];
          let arr1 = arr.sort((a, b) => b.count - a.count);
          setCase(arr1);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const useCaseData = async () => {
    setLoading(true);
    axios.get(`${baseURl}/admin/use-case-statistics/monthly`).then((response) => {});
  };

  const userData = async () => {
    setStatLoading(true);
    try {
      const res = await axios.get(`${baseURl}/dashboardData`);
      const ress = await axios.get(`${baseURl}/landing_data`);
      const allUsers = res.data;
      setData(allUsers);
      setNumbers({
        user: data?.total_users,
        subs: data?.subscribed_users,
        image: ress?.data?.data?.total_images_generated,
        texts: ress?.data?.data?.total_texts_generated,
      });
      setStatLoading(false);
    } catch (err) {
      console.log(err);
      setStatLoading(false);
    }
  };

  useEffect(() => {
    userData();
    dailyData();
    // useCase();
    useCaseData();
  }, []);
  useEffect(() => {
    // userData();
    dailyData();
    // useCase();
    // useCaseData();
  }, [year, month]);

  function getMonthName(monthNumber) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    } else {
      return "Invalid month";
    }
  }

  const user = localStorage.getItem("user");

  const parsedUser = JSON.parse(user);

  const disabledUser = parsedUser?.User?.email !== "omar@mqyas.agency";

  return (
    <>
      <DashboardLayout>
        {disabledUser && <DashboardNavbar />}
        <MDBox py={3}>
          {StatLoading ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <Box mb={1.5} onClick={() => Navigate("/users")} sx={{ cursor: "pointer" }}>
                    <ComplexStatisticsCard color="success" icon="group" title="Total Users" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box mb={1.5} onClick={() => Navigate("/subscriber")} sx={{ cursor: "pointer" }}>
                    <ComplexStatisticsCard icon="group" title="Subscribed Users" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box mb={1.5} onClick={() => Navigate("/images")} sx={{ cursor: "pointer" }}>
                    <ComplexStatisticsCard color="dark" icon="image_search" title="Images Generated" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box mb={1.5} onClick={() => Navigate("/texts")} sx={{ cursor: "pointer" }}>
                    <ComplexStatisticsCard color="primary" icon="article" title="Texts Generated" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <Box
                    mb={1.5}
                    onClick={() => {
                      if (!disabledUser) {
                        return;
                      } else {
                        Navigate("/users");
                      }
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <ComplexStatisticsCard color="success" icon="group" title="Total Users" count=<CountUp end={cardData?.totalCounts?.users} /> />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box
                    mb={1.5}
                    nClick={() => {
                      if (!disabledUser) {
                        return;
                      } else {
                        Navigate("/subscriber");
                      }
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <ComplexStatisticsCard icon="group" title="Subscribed Users" count=<CountUp end={cardData?.totalCounts?.subscripers} /> />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box
                    mb={1.5}
                    nClick={() => {
                      if (!disabledUser) {
                        return;
                      } else {
                        Navigate("/images");
                      }
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <ComplexStatisticsCard color="dark" icon="image_search" title="Images Generated" count=<CountUp end={cardData?.totalCounts?.images} /> />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Box
                    mb={1.5}
                    nClick={() => {
                      if (!disabledUser) {
                        return;
                      } else {
                        Navigate("/texts");
                      }
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <ComplexStatisticsCard color="primary" icon="article" title="Texts Generated" count=<CountUp end={cardData?.totalCounts?.text} /> />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          {!loading ? (
            <>
              <Card
                variant="outlined"
                item
                xs={12}
                md={6}
                lg={3}
                sx={{
                  width: 300,
                  display: "inline",
                  height: 400,
                  p: "0.7rem",
                  fontSize: 14,
                  border: "none",
                }}
              >
                <select
                  disabled={!totalFlag}
                  onChange={(e) => {
                    setMonth(e.target.value || "00");
                    setFlag(false);
                  }}
                  value={month}
                  style={{ border: "none" }}
                  defaultValue={"--Select Month--"}
                >
                  <option value="" selected>
                    --Select Month--
                  </option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
                    return (
                      <option selected value={item}>
                        {getMonthName(item)}
                      </option>
                    );
                  })}
                </select>
                <select
                  disabled={!totalFlag}
                  onChange={(e) => {
                    setYear(e.target.value);
                    setFlag(true);
                  }}
                  value={year}
                  style={{ border: "none" }}
                  defaultValue={"--Select Year--"}
                >
                  {["2023", "2024"].map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
                {/* <Button
                  variant="text"
                  color={totalFlag ? "secondary" : "primary"}
                  onClick={() => {
                    totalFlag ? changeToDailyData() : dailyData();
                    changeToggle();
                  }}
                >
                  Daily
                </Button> */}
              </Card>
              <MDBox mt={4.5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartConversion
                        key={"conversion"}
                        color="warning"
                        title={graph?.length}
                        description="Last Campaign Performance"
                        date="campaign sent 2 days ago"
                        // chart={reportsBarChartData}
                        graph={graph}
                        dgraph={dgraph}
                        flag={flag}
                        totalFlag={totalFlag}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChart
                        key={"performance"}
                        color="success"
                        title="website views"
                        description="Last Campaign Performance"
                        date="campaign sent 2 days ago"
                        // chart={reportsBarChartData}
                        graph={graph}
                        dgraph={dgraph}
                        flag={flag}
                        totalFlag={totalFlag}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartS key={"sub"} color="info" graph={graph} month={month} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartSubsandRenews key={"user_case"} color="secondary" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartNewSubs key={"user_case"} color="dark" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartWebsiteSub key={"user_case"} color="light" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartSubscriptionbyMethod key={"user_case"} color="light" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartSubscriptionbyPlatform key={"user_case"} color="light" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartCombined key={"user_case"} color="dark" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartAudioVideo key={"user_case"} color="dark" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <Card sx={{ maxHeight: "360px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            background: "#151723",
                            borderRadius: "8px",
                            padding: "10px",
                            margin: "16px",
                            boxShadow: "0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.4375rem 0.625rem -0.3125rem rgba(64, 64, 64, 0.4)",
                          }}
                        >
                          {["Use Case", "Total no.of use"].map((items) => (
                            <div style={{ color: "#fff", fontSize: "1rem" }}>{items}</div>
                          ))}
                        </div>
                        {cases?.slice(0, 5).map((item) => (
                          <div
                            style={{
                              width: "100",
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <TableCell sx={{ width: "70%" }} align="left">
                              {item?.useCase ? item?.useCase.replace("Create", "Generate Image") : "Code"}
                            </TableCell>
                            <TableCell align="left">
                              <CountUp end={item?.count} />
                            </TableCell>
                          </div>
                        ))}

                        <button style={{ background: "none", border: "none" }} onClick={() => Navigate("/useCase")}>
                          <MoreHorizRoundedIcon fontSize="large" />
                        </button>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarChartArabyGpt key={"user_case"} color="secondary" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportBarChartDocument key={"user_case"} color="secondary" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarPdfChat key={"user_case"} color="secondary" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3}>
                      <ReportsBarHTML key={"user_case"} color="secondary" graph={graph} dgraph={dgraph} flag={flag} totalFlag={totalFlag} />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          ) : (
            <LoadingAnimation />
          )}
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
