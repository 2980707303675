import Axios from "axios";
const BaseURL = Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const token = localStorage.getItem("token");
if (token) {
    BaseURL.defaults.headers.common["Authorization"] = `${token}`;
}
// BaseURL.defaults.headers.post["Content-Type"] = "application/json";


const addCourse = (data) => {
    return BaseURL.postForm(`course/add`, data);
};

const allCourse = (count, limit) => {
    return BaseURL.get(`/course/get?page=${count}&limit=${limit}`);
};
const allCourses = () => {
    return BaseURL.get("/course/get");
};

const deleteCourse = (id) => {
    return BaseURL.delete(`/course/delete/${id}`);
};



const getCourseById = (id) => {
    return BaseURL.get(`/course/get/${id}`);
}
const updateCourse = (id, data) => {
    return BaseURL.putForm(`/course/edit/${id}`, data);
};


const Course = {
    allCourse,
    allCourses,
    addCourse,
    deleteCourse,
    getCourseById,
    updateCourse
};

export default Course;
