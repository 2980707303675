import React, { useState } from 'react';

function StepsForm({steps, setSteps}) {
    // const [steps, setSteps] = useState(['']);

    const handleStepChange = (index, value) => {
        const newSteps = [...steps];
        newSteps[index] = value;
        setSteps(newSteps);
    };

    const addStep = () => setSteps([...steps, '']);
    const removeStep = (index) => setSteps([...steps.slice(0, index), ...steps.slice(index + 1)]);

    return (
        <div>
            {steps.map((step, index) => (
                <div key={index} style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"5px"}}>
                    {index < steps.length - 1 && (
                        <button onClick={() => removeStep(index)} style={{ borderRadius: "5px", border: "1px solid #D3D3D3", padding: "0px 5px" }}>-</button>
                    )}
                    <input
                        style={{ borderRadius: "10px", outline: "none", border: "1px solid #D3D3D3" }}
                        type="text"
                        value={step}
                        onChange={(e) => handleStepChange(index, e.target.value)}
                    />
                    <button onClick={addStep} style={{ borderRadius: "5px", padding: " 0px 3px", border: "1px solid #D3D3D3" }}>+</button>
                </div>
            ))}

        </div>
    );
}

export default StepsForm;
