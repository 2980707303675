import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { baseURl } from "BaseUrl";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import LoadingAnimation from "components/Animation/LoadingAnimation";

import SurveyTable from "layouts/Survey/data/SurveyTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Box, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import axios from "axios";
import AnsweredSkippedChart from "./answeredSkippedChart";
import AvgDurationChart from "./averageDurationchart";
import ResponsesDistributionChart from "./allQuestionsChart";

function Survey() {
  const Navigate = useNavigate();

  const [StatLoading, setStatLoading] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(true);
  const [cardData, setCardData] = useState({});
  var { columns, rows, data, loading, pageLimit, limit, total, count, setCount, setLimit } = SurveyTable();

  const userData = async () => {
    setStatLoading(true);
    try {
      const res = await axios.post(`${baseURl}/survey/survey-statistics`, {
        queryType: "total_users",
      });
      const res2 = await axios.post(`${baseURl}/survey/survey-statistics`, {
        queryType: "total_surveys",
      });
      const res3 = await axios.post(`${baseURl}/survey/survey-statistics`, {
        queryType: "skipped",
      });
      const res4 = await axios.post(`${baseURl}/survey/survey-statistics`, {
        queryType: "answered",
      });
      setCardData({
        totalCounts: {
          total_users: res.data.data,
          total_surveys: res2.data.data,
          skipped: res3.data.data,
          answered: res4.data.data,
        },
      });
      setStatLoading(false);
    } catch (err) {
      console.log(err);
      setStatLoading(false);
    }
  };

  const graphDataFetch = async () => {
    setGraphLoading(true);
    try {
      const res = await axios.post(`${baseURl}/survey/survey-statistics`, {
        queryType: "answered_skipped_per_question",
      });
      const res2 = await axios.post(`${baseURl}/survey/survey-statistics`, {
        queryType: "avg_duration_per_question",
      });
      const res3 = await axios.post(`${baseURl}/survey/survey-statistics`, {
        queryType: "group_by_answer_and_question",
      });
      setGraphData({
        answered_skipped_per_question: res.data.data,
        avg_duration_per_question: res2.data.data,
        group_by_answer_and_question: res3.data.data,
      });
      setGraphLoading(false);
    } catch (err) {
      console.log(err);
      setGraphLoading(false);
    }
  };

  useEffect(() => {
    userData();
    graphDataFetch();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={2}
                py={1.5}
                px={1.5}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h5" color="white">
                  Survey
                </MDTypography>
              </MDBox>
              {StatLoading ? (
                <Box p={2}>
                  <Grid container spacing={3} mt={5} mb={5} pl={2} pr={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/users")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard color="success" icon="group" title="Total User Got Survey" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/subscriber")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard icon="group" title="Total Surveys" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/images")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard color="dark" icon="image_search" title="Skipped Surveys" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/texts")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard color="primary" icon="article" title="Answered" count=<Skeleton sx={{ borderRadius: "5px" }} variant="rectangular" /> />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/users")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard color="success" icon="group" title="Total Users got Surveys" count=<CountUp end={cardData?.totalCounts?.total_users[0]?.totalUsers} /> />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/subscriber")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard icon="group" title="Total Survey Appeared" count=<CountUp end={cardData?.totalCounts?.total_surveys[0]?.totalSurveys} /> />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/images")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard color="dark" icon="image_search" title="Total Skipped Surveys" count=<CountUp end={cardData?.totalCounts?.skipped[0]?.totalNullAnswers} /> />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box mb={1.5} onClick={() => Navigate("/texts")} sx={{ cursor: "pointer" }}>
                        <ComplexStatisticsCard color="primary" icon="article" title="Total Answered Surveys" count=<CountUp end={cardData?.totalCounts?.answered[0]?.totalNonNullAnswers} /> />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Box p={2}>
                <AnsweredSkippedChart data={graphData} />
                <AvgDurationChart data={graphData} />
                <ResponsesDistributionChart questionData={graphData?.group_by_answer_and_question} />
              </Box>

              <MDBox>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    pagination={true}
                    canSearch={false}
                    datas={data}
                    pageLimit={pageLimit}
                    limit={limit}
                    setLimit={setLimit}
                    total={total}
                    count={count}
                    setCount={setCount}
                  />
                ) : (
                  <LoadingAnimation />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Survey;
