import Newsletter from "apis/newsletter";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useEffect, useState } from "react";

export default function data() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [total, setTotal] = useState();
  const [pageLimit, setPageLimit] = useState();
  useEffect(() => {
    newsLetterData();
  }, [refresh, count, limit]);

  const newsLetterData = async () => {
    try {
      setLoading(true);
      // const res = await axios.get(`http://127.0.0.1:5050/admin/enterprise`);
      const res = await Newsletter.getAll();
      const allPlans = res.data.data;
      setData(allPlans);
      const total = res.data.count;
      setCount(total);
      const pLimit = Math.floor(total / limit + 1);
      setPageLimit(pLimit);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  // planData();
  return {
    columns: [
      { Header: "Email", accessor: "email", align: "left", width: "20%" },
      { Header: "Created At", accessor: "created", align: "center" },
    ],

    rows: data?.map((item) => ({
      row: item,
     email: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {item?.email}
        </MDTypography>
      ),

      created: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(item?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
        </MDTypography>
      ),
    })),
    data: data,
    loading: loading,
    refresh,
    setRefresh,
    pageLimit: pageLimit,
    limit: limit,
    setLimit: setLimit,
    count: count,
    setCount: setCount,
  };
}
