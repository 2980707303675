import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { baseURl } from "BaseUrl";
import moment from "moment";
import Enterprice from "apis/enterprice";
import MDAvatar from "components/MDAvatar";
import { useParams } from "react-router-dom";
import { Toolbar, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import MDBadge from "components/MDBadge";

export default function TeamTable() {
    const params = useParams();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [pageLimit, setPageLimit] = useState();
  useEffect(() => {
      enterpriseData();
  }, [refresh, count, limit]);

  const enterpriseData = async () => {
    try {
      setLoading(true);
      const res = await axios.get( `${baseURl}/admin/enterprise/${params.id}?page=${count}&limit=${limit}`);
      // const res = await Enterprice.getAll();
      const allPlans = res.data.data;
      setData(allPlans);
      const total = res.data.count;
      setTotal(total)
      // setCount(total);
      const pLimit = Math.floor(total / limit + 1);
      setPageLimit(pLimit);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
   }

  // const enterpriseData = async () => {
  //   try {
  //     setLoading(true);
  //     // const res = await axios.get(`http://127.0.0.1:5050/admin/enterprise`);
  //     const res = await Enterprice.getAll();
  //     const allPlans = res.data.data;
  //     setData(allPlans);
  //     const total = res.data.count;
  //     setCount(total);
  //     const pLimit = Math.floor(total / limit + 1);
  //     setPageLimit(pLimit);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // planData();
  return {
    columns: [
      { Header: "NAME", accessor: "name", align: "left", width: "20%" },
      // { Header: "Data Allowance", accessor: "data", align: "center" },
      // { Header: "Category", accessor: "category", align: "center" },
          { Header: "Joined At", accessor: "joinedAt", align: "center" },
          { Header: "Count Left", accessor: "count_left", align: "center" },
      { Header: "Limit", accessor: "limit", align: "center" },
    ],

    rows: data?.map((item) => ({
      row: item,
      name: (
        // <MDTypography
        //   component="a"
        //   href="#"
        //   variant="caption"
        //   color="text"
        //   fontWeight="medium"
        // >
        //   {item?.name} <br/>
        //   {item?.owner.email}
        //   <MDAvatar src={item?.owner.image}  size="sm" />
        //   {/* {item?.owner.image} */}
        // </MDTypography>
        <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={item?.user?.image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {item?.user.name}
          </MDTypography>
                  <MDTypography variant="caption">{item?.user.email}</MDTypography>
                  
              </MDBox>
              {item.deletedAt ?
                  <Tooltip title={"deleted"}>
                  <MDBadge
                  badgeContent=<CancelIcon />
                  color="error"
                  variant="gradient"
                  size="sm"
                      />
             </Tooltip>
                      :null
              }
               
              
             
      </MDBox>
      ),

    

      joinedAt: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
              {
                //   moment(item?.joinedAt).format("DD/MM/YYYY,h:mm:ss a")
                      (item.joinedAt === null && item.deletedAt ===null) ? "Pending" :  moment(item?.joinedAt).format("DD/MM/YYYY,h:mm:ss a")
                   }
          </MDTypography>
          //moment(item?.joinedAt).format("DD/MM/YYYY,h:mm:ss a")
          //item.joinedAt = "Pending"
      ),
    //   max_users: (
    //     <MDTypography
    //     component="a"
    //     href="#"
    //     variant="caption"
    //     color="text"
    //     fontWeight="medium"
    //   >
    //     {item?.max_users}
    //   </MDTypography>
    //   ),
    count_left: (
        <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
          Text Count Left: {item?.user?.text_count_left} <br/>
          Image Count Left: {item.user?.image_count_left}
        </MDTypography>
        
      ),
      limit: (
        <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
          Text Limit: {item?.text_limit} <br/>
          Image Limit: {item?.image_limit}
        </MDTypography>
        
      ),
    })),
    data: data,
    loading: loading,
    refresh,
    setRefresh,
    setCount: setCount,
    pageLimit: pageLimit,
    limit: limit,
    setLimit: setLimit,
    count: count,
    total:total,
    
  };
}
