import BaseURL from "./baseUrl";

// All apis for course categories
const allSkills = (count, limit) => {
    return BaseURL.get(`/course-skill/get?page=1&limit=10`);
};

const addSkills = (data) => {
    return BaseURL.post(`/course-skill/add`, data);
};


const deleteSkills = (id) => {
    return BaseURL.delete(`/course-skill/delete/${id}`);
};

// const getBlogById = (id) => {
//     return BaseURL.get(`/admin/blog/${id}`);
// }
const updateSkills = (id, data) => {
    return BaseURL.put(`/course-skill/edit/${id}`, data);
};

const CourseSkills = {
    allSkills,
    addSkills,
    deleteSkills,
    updateSkills
};
export default CourseSkills