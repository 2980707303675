import React, { useState, useEffect } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Form from "react-bootstrap/Form";
import Rte from "components/BlogNote/Rte";
import { Button } from "react-bootstrap";
import CourseInstructor from 'apis/courseInstructor';

import LoadingAnimation from 'components/Animation/LoadingAnimation';
import MDSnackbar from 'components/MDSnackbar';
import CourseFeature from 'apis/courseFeatures';
import { useNavigate, useParams } from 'react-router-dom';

const CreateBlog = () => {
    const params = useParams()
    const [data, setData] = useState("")
    const [titleEn, setTitleEN] = useState("");
    const [titleAr, setTitleAr] = useState("");
    const [level, setLevel] = useState("")
    const [title, setTitle] = useState();
    const [message, setMessage] = useState();
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(1);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [cover, setCover] = useState("");
    const [changeImage, setChangeImage] = useState(false)
    const navigate = useNavigate();
    const addCourseCategory = async () => {
        setLoading(true);
        setErrors(false);
        const formData = new FormData();
        formData.append("name_en", titleEn);
        formData.append("name_ar", titleAr);
        if(changeImage){
        formData.append("file", cover);
        }
        let data = {
            "name": {
                "en": titleEn,
                "ar": titleAr
            },
            "file": (cover),
        };

        if (
            !titleEn ||
            !titleAr ||
            !cover
        ) {
            setErrors(true);
            setLoading(false);
            window.scrollTo(0, 0);
        } else {
            try {
                if(cover){
                    const res = await CourseInstructor.updateInstructor(params?.id, formData);
                    if(res.data){
                        setSuccessSB(true)
                        setMessage("Course Instructor Updated successfully")
                        setTitle("success");
                            setTimeout(() => {
                                navigate("/courseinstructor");
                              }, 2000);
                            }
                }
                
                setLoading(false)
                
            } catch (err) {
                setLoading(false)
                setSuccessSB(true)
                setMessage("SomeThing Went Wrong")
                setTitle("error");
            }
        }
    };
    useEffect(() => {
        if (data) {
            setTitleEN(data?.name?.en || "")
            setTitleAr(data?.name?.ar || "")
            setCover(data?.image)
        }
    }, [data])
    useEffect(() => {
        if (params?.id && !data) {
            fetchBlog(params?.id)
        }
    }, [params])

    const fetchBlog = async (id) => {
        try {
            const res = await CourseInstructor.getInstructorById(id);
            setLoading(false);
            setData(res?.data?.data)
        } catch (err) {
            setLoading(false);
            setSuccessSB(true)
            setMessage("SomeThing Went Wrong")
            setTitle("error");
        }
    }
    const clearData = () => {
        setTitleEN("");
        setTitleAr("");
        setCover("")
    }
    const handleFileChange = (e) => {
        if (e.target.files) {
            setCover(e.target.files[0]);
        }
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <MDSnackbar
                color={title}
                icon="check"
                title={title}
                content={message}
                open={successSB}
                onClose={() => setSuccessSB(false)}
                close={() => setSuccessSB(false)}
            />
            {loading ? (
                <LoadingAnimation />
            ) : (
                <MDBox position="relative" mb={5}>
                    <Card
                        sx={{
                            position: "relative",
                            mt: 2,
                            // mx: 3,
                            py: 2,
                            px: 2,
                        }}
                    >
                        <Grid container spacing={6} alignItems="center">

                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title"
                                        value={titleEn}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleEN(e.target.value)}
                                    />
                                    {errors && (titleEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid><Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Name (AR)"
                                        value={titleAr}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleAr(e.target.value)}
                                    />
                                    {errors && (titleAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} width="100%">
                                {changeImage ? <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Cover Image(400 x 284):
                                    </MDTypography>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control
                                            onChange={handleFileChange}
                                            type="file"
                                            accept="image/*" 
                                            placeholder="choose cover"
                                        />
                                    </Form.Group>
                                    {errors && (cover === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox> :
                                    <MDBox mt={1}>
                                        <div className="d-flex gap-2" style={{ width: "100%" }}>
                                            <img src={data?.image} style={{ objectFit: "contain", width: "40px" }} />
                                            <Button variant="primary" onClick={() => setChangeImage(true)}>
                                                Change Image
                                            </Button>
                                        </div>
                                    </MDBox>}

                            </Grid>

                            <Grid item lg={4} md={4} />
                            <Grid item lg={4} md={4} sm={12} width="100%">
                                <MDBox mt={1}>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" onClick={addCourseCategory}>
                                            Save
                                        </Button>
                                    </div>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Card>
                </MDBox>
            )}
        </DashboardLayout>
    )
}

export default CreateBlog