import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Checkbox, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { baseURl } from "BaseUrl";
import MDAvatar from "components/MDAvatar";
import Category from "apis/category";

function CategoryDetail({ category, categoryUpdated }) {
  const [nameEn, setNameEN] = useState(category.name_en);
  const [nameAr, setNameAr] = useState(category.name_ar);
  const [descEn, setDescEN] = useState(category.desc_en);
  const [descAr, setDescAr] = useState(category.desc_ar);
  const [icon, setIcon] = useState();
  const [rank, setRank] = useState(category.rank);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setIcon(e.target.files[0]);
    }
  };

  const updateCategory = async () => {
    let data = {
      name_en: nameEn,
      name_ar: nameAr,
      desc_en: descEn,
      desc_ar: descAr,
      file: icon,
      rank: rank,
    };
    console.log("data", data);
    // categoryUpdated();
    try {
      const res = await Category.editCategory(data, category._id);
      categoryUpdated();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        padding: "10px",
      }}
    >
      <MDBox width="100%">
        <MDTypography variant="button" fontWeight="regular" color="text">
          Name (en):
        </MDTypography>
        <MDInput
          placeholder="Name"
          value={nameEn}
          size="small"
          fullWidth
          onChange={(e) => setNameEN(e.target.value)}
        />
      </MDBox>

      <MDBox width="100%">
        <MDTypography variant="button" fontWeight="regular" color="text">
          Name (ar):
        </MDTypography>
        <MDInput
          placeholder="Name (AR)"
          value={nameAr}
          size="small"
          fullWidth
          onChange={(e) => setNameAr(e.target.value)}
        />
      </MDBox>

      <MDBox width="100%">
        <MDTypography variant="button" fontWeight="regular" color="text">
          Description (en):
        </MDTypography>
        <MDInput
          placeholder="Description"
          value={descEn}
          size="small"
          fullWidth
          onChange={(e) => setDescEN(e.target.value)}
        />
      </MDBox>

      <MDBox width="100%">
        <MDTypography variant="button" fontWeight="regular" color="text">
          Description (ar):
        </MDTypography>
        <MDInput
          placeholder="Description (AR)"
          value={descAr}
          size="small"
          fullWidth
          onChange={(e) => setDescAr(e.target.value)}
        />
      </MDBox>

      <MDBox width="100%">
        <MDTypography variant="button" fontWeight="regular" color="text">
          Icon:
        </MDTypography>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            onChange={handleFileChange}
            type="file"
            placeholder="choose icon"
          />
        </Form.Group>
        {/* <MDInput
          placeholder="Text"
          value={icon}
          size="small"
          fullWidth
          onChange={(e) => setIcon(e.target.value)}
        /> */}
      </MDBox>

      <MDBox width="100%">
        <MDTypography variant="button" fontWeight="regular" color="text">
          Rank:
        </MDTypography>
        <MDInput
          placeholder="Text"
          type="number"
          value={rank}
          size="small"
          fullWidth
          onChange={(e) => setRank(e.target.value)}
        />
      </MDBox>

      <MDBox mt={1} width="100%">
        <div className="d-grid gap-2">
          <Button variant="primary" onClick={updateCategory}>
            Save
          </Button>
        </div>
      </MDBox>
    </Card>
  );
}

export default CategoryDetail;
