import Grid from "@mui/material/Grid";
import { useState } from "react";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import LoadingAnimation from "components/Animation/LoadingAnimation";

import SurveyTable from "layouts/Webinar/data/SurveyTable";
import moment from "moment";

function Webinar() {
  var { columns, rows, data, loading, pageLimit, limit, total, count, setCount, setLimit } = SurveyTable();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={2}
                py={1.5}
                px={1.5}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h5" color="white">
                  Webinar
                </MDTypography>
                <MDTypography variant="h5" color="white">
                  Total People: {total}
                </MDTypography>
              </MDBox>
              <MDBox>
                {!loading ? (
                  <MDBox display="flex" flexWrap="wrap" justifyContent="center">
                    {data.map((item, index) => {
                      const isPast = moment(item.date).isBefore(moment(), "day");
                      const bgColor = isPast ? "grey" : "info";
                      const textColor = isPast ? "black" : "info";

                      return (
                        <MDBox
                          key={index}
                          width="120px"
                          height="120px"
                          borderRadius="lg"
                          bgcolor={bgColor}
                          color={textColor}
                          mx={2}
                          my={2}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          boxShadow={3}
                          p={2}
                        >
                          <MDTypography variant="h5" color={textColor} fontWeight="bold" mb={1}>
                            {item.count}
                          </MDTypography>
                          <MDTypography variant="h6" color={textColor} mb={1}>
                            {moment(item.date).format("DD-MM-YYYY")}
                          </MDTypography>
                          <MDTypography variant="h6" color={textColor}>
                            {item.hour}
                          </MDTypography>
                        </MDBox>
                      );
                    })}
                  </MDBox>
                ) : (
                  <LoadingAnimation />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Webinar;
