import BaseURL from "./baseUrl";
// All apis for course categories
const allInstructor = (count, limit) => {
    return BaseURL.get(`/course-instructor/get?page=1&limit=10`);
};

const addInstructor = (data) => {
    return BaseURL.postForm(`/course-instructor/add`, data);
};


const deleteInstructor = (id) => {
    return BaseURL.delete(`/course-instructor/delete/${id}`);
};

const updateInstructor = (id, data) => {
    return BaseURL.putForm(`/course-instructor/edit/${id}`, data);
}
const getInstructorById = (id, data) => {
    return BaseURL.get(`/course-instructor/get/${id}`, data);
};

const CourseInstructor = {
    allInstructor,
    addInstructor,
    deleteInstructor,
    updateInstructor,
    getInstructorById
};
export default CourseInstructor