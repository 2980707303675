import BaseURL from "./baseUrl";

const getAll = () => {
  return BaseURL.get(`course-feature/get`);
};

const Feature = {
  getAll,
};

export default Feature;
