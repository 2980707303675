import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Checkbox, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { baseURl } from "BaseUrl";

function AddPlan({ planUpdated }) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState();
  const [image, setImage] = useState();
  const [text, setText] = useState();
  const [active, setActive] = useState(true.toString());
  const [category, setCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [errors, serErrors] = useState([]);

  const addPlan = async () => {
    let data = {
      plan_name: name,
      monthly_price: price,
      image_count_left: image,
      text_count_left: text,
      active: active == "true",
      category,
    };
    // planUpdated();
    try {
      const res = await axios.post(`${baseURl}/v2/admin/plan/create`, data);
      planUpdated();
      // const allPlans = res.data.products;
      // setData(allPlans);

      // setLoading(false);
    } catch (err) {
      serErrors(err.response.data.error);
      console.log("errors", errors);
    }
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(`${baseURl}/plans/category/get`);
      const allCats = res.data.categories;
      setCategories(allCats);

      //   setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        padding: "10px",
        alignItems: "center",
      }}
    >
      <>
        <MDBox width="80%">
          {errors.length
            ? errors.map((error) => {
                return (
                  <>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="error"
                    >
                      {error.message}
                    </MDTypography>
                    <br />
                  </>
                );
              })
            : null}
          <MDTypography variant="button" fontWeight="regular" color="text">
            Plan name:
          </MDTypography>
          <MDInput
            placeholder="Name"
            value={name}
            size="small"
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Plan price:
          </MDTypography>
          <MDInput
            placeholder="Price"
            value={price}
            size="small"
            fullWidth
            onChange={(e) => setPrice(e.target.value)}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Category:
          </MDTypography>
          <Form.Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            aria-label="Default select example"
          >
            <option value="">Select category</option>
            {categories.map((category) => {
              return <option value={category._id}>{category.name_en}</option>;
            })}
            {/* <option>Choose from list</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option> */}
          </Form.Select>
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Text count:
          </MDTypography>
          <MDInput
            placeholder="Text"
            value={text}
            size="small"
            fullWidth
            onChange={(e) => setText(e.target.value)}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Image count:
          </MDTypography>
          <MDInput
            placeholder="Search..."
            value={image}
            size="small"
            fullWidth
            onChange={(e) => setImage(e.target.value)}
            // onChange={(({ currentTarget }) => {
            //   setSearch(search);)
            //   // onSearchChange(currentTarget.value);
            // }}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Is active:
          </MDTypography>
          <Form.Select
            value={active}
            onChange={(e) => setActive(e.target.value)}
            aria-label="Default select example"
          >
            <option>Choose from list</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </Form.Select>
        </MDBox>

        <MDBox mt={1} width="80%">
          <div className="d-grid gap-2">
            <Button variant="primary" onClick={addPlan}>
              Save
            </Button>
          </div>
        </MDBox>
      </>
    </Card>
  );
}

export default AddPlan;
