import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import CourseSkills from "apis/courseSkills"
import Course from "apis/courses.js";
import MDAvatar from "components/MDAvatar";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function data() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [pageLimit, setPageLimit] = useState();
    const [count, setCount] = useState(1);
    const [total, setTotal] = useState();

    useEffect(() => {
        courseData();
    }, [refresh, count, limit]);
    const courseData = async () => {
        try {
            setLoading(true);
            const res = await Course.allCourse(count, limit);
            setData(res?.data?.data?.docs);
            setPageLimit(Math.floor(res?.data?.data?.totalDocs / limit + 1));
            setTotal(res?.data?.data?.totalDocs);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    // courseData();
    return {
        columns: [
            { Header: "Title ", accessor: "name", align: "left", width: "20%" },
            { Header: "Action", accessor: "action", align: "center" },

        ],

        rows: data?.map((course) => ({
            row: course,
            name: (<>
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    sx={{ display: 'inline-block', maxWidth: "250px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {course?.title?.en || "N/A"}
                </MDTypography>
                <br />
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    sx={{ display: 'inline-block', maxWidth: "250px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {course?.title?.ar || "N/A"}
                </MDTypography>
            </>
            ),
            // time: (<>
            //     <MDTypography
            //         component="a"
            //         href="#"
            //         variant="caption"
            //         color="text"
            //         fontWeight="medium"
            //     >
            //         Created At: {moment(course?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
            //     </MDTypography>
            //     <br />
            //     <MDTypography
            //         component="a"
            //         href="#"
            //         variant="caption"
            //         color="text"
            //         fontWeight="medium"
            //     >
            //         Published At: {moment(course?.updatedAt).format("DD/MM/YYYY,h:mm:ss a")}

            //     </MDTypography>
            // </>
            // ),

            action: (
                <div className="d-flex gap-5">
                    {/* <Button
                        onClick={() => {
                            navigate(`/create-coursevideo`)
                        }}
                        variant="success"
                    >
                        Add Video
                    </Button> */}
                    <Button
                        variant="primary"
                        onClick={() => {
                            navigate(`/viewcourse-video/${course._id}`)
                        }}
                    >
                        View all Videos
                    </Button>
                </div>
            ),
        })),
        data: data,
        loading: loading,
        alert,
        setAlert,
        message,
        refresh,
        setRefresh,
        pageLimit: pageLimit,
        limit: limit,
        setLimit: setLimit,
        count: count,
        setCount: setCount,
        total: total
    };
}
