import React, { useEffect } from "react";
import { Modal, Typography, Box, Stack, Divider } from "@mui/material";
import { Button } from "react-bootstrap";

const GptDetailsModal = ({ openModal, handleCloseModal, data, gptNext, setGptNext, setRefId }) => {

  return (
    <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "800px",
          overflow: "auto",
        }}
      >
        <Stack direction={"row"} gap={2}>
          <Typography id="modal-title" variant="h6" component="h2">
            Count : {data?.count}
          </Typography>
          <Typography id="modal-title" variant="h6" component="h2">
            page : {gptNext} of {Math.round(data?.count / 10)}
          </Typography>
        </Stack>
        {data?.data?.reverse().map((item, index) => (
          <Stack gap={2}>
            <Typography
              key={index}
              id="modal-description"
              sx={{
                color: item?.role === "user" ? "blue" : "red",
              }}
            >
              {item?.role}
            </Typography>

            <Typography
              key={index}
              id="modal-description"
              sx={{
                whiteSpace: "pre-wrap",
              }}
            >
              {item?.message}
            </Typography>
            <Divider />
          </Stack>
        ))}
        {data?.count > 10 && (
          <Stack gap={2} direction={"row"}>
            <Button
              disabled={gptNext <= 1}
              onClick={() => {
                setGptNext(gptNext - 1);
                setRefId(data.data[0].ref_id);
              }}
            >
              Prev
            </Button>
            <Button
              disabled={gptNext === Math.round(data?.count / 10)}
              onClick={() => {
                setGptNext(gptNext + 1);
                setRefId(data.data[0].ref_id);
              }}
            >
              Next
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default GptDetailsModal;
