import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Checkbox, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { baseURl } from "BaseUrl";
import Feature from "apis/feature";

import { MultiSelect } from "react-multi-select-component";

function AddPlan({ planUpdated }) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState();
  const [image, setImage] = useState();
  const [text, setText] = useState();
  const [active, setActive] = useState(true.toString());
  const [category, setCategory] = useState();
  const [featureList, setFeatureList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errors, serErrors] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [showAddCustom, setShowAddCustom] = useState(false);
  const [newFeatures, setNewFeatures] = useState([]);
  const [featEn, setFeatEn] = useState("");
  const [featAr, setFeatAr] = useState("");
  const [yearlyPrice, setYearlyPrice] = useState();

  const addPlan = async () => {
    const features = selectedFeatures.map((feat) => {
      return feat.value;
    });
    let data = {
      plan_name: name,
      monthly_price: price,
      yearly_price: yearlyPrice,
      image_count_left: image,
      text_count_left: text,
      active: active == "true",
      category,
      features,
      new_features: newFeatures,
    };
    // planUpdated();
    try {
      const res = await axios.post(`${baseURl}/v2/admin/plan/create`, data);
      planUpdated();
    } catch (err) {
      serErrors(err.response.data.error);
      console.log("errors", errors);
    }
  };

  const addFeature = () => {
    if (!featEn || !featAr || featEn == "" || featAr == "") {
      alert("feat_en and feat_ar are mandatory");
      return false;
    }
    var features = newFeatures;
    features.push({
      feat_en: featEn,
      feat_ar: featAr,
    });
    setNewFeatures(features);
    setFeatAr("");
    setFeatEn("");
    setShowAddCustom(false);
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(`${baseURl}/plans/category/get`);
      const allCats = res.data.categories;
      setCategories(allCats);

      //   setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getFeatures = async () => {
    try {
      // const res = await axios.get(`http://127.0.0.1:5050/admin/enterprise`);
      const res = await Feature.getAll();
      const allFeatures = res.data.features;
      var optionList = allFeatures.map((feature) => {
        return {
          label: feature.feat_en,
          value: feature._id,
        };
      });
      setFeatureList(optionList);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCategories();
    getFeatures();
  }, []);
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        padding: "10px",
        alignItems: "center",
      }}
    >
      <>
        <MDBox width="80%">
          {errors.length
            ? errors.map((error) => {
                return (
                  <>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="error"
                    >
                      {error.message}
                    </MDTypography>
                    <br />
                  </>
                );
              })
            : null}
          <MDTypography variant="button" fontWeight="regular" color="text">
            Plan name:
          </MDTypography>
          <MDInput
            placeholder="Name"
            value={name}
            size="small"
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Plan price:
          </MDTypography>
          <MDInput
            placeholder="Price"
            value={price}
            size="small"
            fullWidth
            onChange={(e) => setPrice(e.target.value)}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Yearly price:
          </MDTypography>
          <MDInput
            placeholder="Yearly Price"
            value={yearlyPrice}
            size="small"
            fullWidth
            onChange={(e) => setYearlyPrice(e.target.value)}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Category:
          </MDTypography>
          <Form.Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            aria-label="Default select example"
          >
            <option value="">Select category</option>
            {categories.map((category) => {
              return <option value={category._id}>{category.name_en}</option>;
            })}
          </Form.Select>
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Text count:
          </MDTypography>
          <MDInput
            placeholder="Text"
            value={text}
            size="small"
            fullWidth
            onChange={(e) => setText(e.target.value)}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Image count:
          </MDTypography>
          <MDInput
            placeholder="Search..."
            value={image}
            size="small"
            fullWidth
            onChange={(e) => setImage(e.target.value)}
            // onChange={(({ currentTarget }) => {
            //   setSearch(search);)
            //   // onSearchChange(currentTarget.value);
            // }}
          />
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Is active:
          </MDTypography>
          <Form.Select
            value={active}
            onChange={(e) => setActive(e.target.value)}
            aria-label="Default select example"
          >
            <option>Choose from list</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </Form.Select>
        </MDBox>

        <MDBox width="80%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Features:
          </MDTypography>
          <MultiSelect
            options={featureList}
            value={selectedFeatures}
            onChange={setSelectedFeatures}
            labelledBy="Select"
          />
        </MDBox>

        <MDBox width="80%">
          <a
            href="#"
            onClick={() => {
              setShowAddCustom(true);
            }}
          >
            Add new feature
          </a>
          {showAddCustom && (
            <div>
              <MDBox mt={1}>
                <MDInput
                  placeholder="Feature english"
                  value={featEn}
                  size="small"
                  fullWidth
                  onChange={(e) => setFeatEn(e.target.value)}
                />
              </MDBox>

              <MDBox mt={1}>
                <MDInput
                  mt={1}
                  placeholder="Feature arabi"
                  value={featAr}
                  size="small"
                  fullWidth
                  onChange={(e) => setFeatAr(e.target.value)}
                />
              </MDBox>

              <MDBox mt={1}>
                <Button variant="primary" onClick={addFeature}>
                  Add feature
                </Button>
              </MDBox>
            </div>
          )}
        </MDBox>

        <MDBox mt={1} width="80%">
          <div className="d-grid gap-2">
            <Button variant="primary" onClick={addPlan}>
              Save
            </Button>
          </div>
        </MDBox>
      </>
    </Card>
  );
}

export default AddPlan;
