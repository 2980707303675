import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Category from "apis/category";

function AddCategory({ categoryUpdated }) {
  const [nameEn, setNameEN] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [descEn, setDescEN] = useState("");
  const [descAr, setDescAr] = useState("");
  const [icon, setIcon] = useState();
  const [rank, setRank] = useState();
  const [errors, setErrors] = useState();

  const addCategory = async () => {
    let data = {
      name_en: nameEn,
      name_ar: nameAr,
      desc_en: descEn,
      desc_ar: descAr,
      file: icon,
      rank: rank,
    };
    // categoryUpdated();
    try {
      // const res = await axios.post(`${baseURl}/v2/admin/category/create`, data);
      const res = await Category.addCategory(data);
      categoryUpdated();
    } catch (err) {
      console.log(err);
      setErrors(err.response.data);
      console.log("errors", errors);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setIcon(e.target.files[0]);
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        padding: "10px",
        alignItems: "center",
      }}
    >
      <>
        {errors && (
          <MDTypography variant="button" fontWeight="regular" color="error">
            {errors.message}
          </MDTypography>
        )}
        <MDBox width="100%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Name (en):
          </MDTypography>
          <MDInput
            placeholder="Name"
            value={nameEn}
            size="small"
            fullWidth
            onChange={(e) => setNameEN(e.target.value)}
          />
        </MDBox>

        <MDBox width="100%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Name (ar):
          </MDTypography>
          <MDInput
            placeholder="Name (AR)"
            value={nameAr}
            size="small"
            fullWidth
            onChange={(e) => setNameAr(e.target.value)}
          />
        </MDBox>

        <MDBox width="100%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Description (en):
          </MDTypography>
          <MDInput
            placeholder="Description"
            value={descEn}
            size="small"
            fullWidth
            onChange={(e) => setDescEN(e.target.value)}
          />
        </MDBox>

        <MDBox width="100%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Description (ar):
          </MDTypography>
          <MDInput
            placeholder="Description (AR)"
            value={descAr}
            size="small"
            fullWidth
            onChange={(e) => setDescAr(e.target.value)}
          />
        </MDBox>

        <MDBox width="100%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Icon:
          </MDTypography>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              onChange={handleFileChange}
              type="file"
              placeholder="choose icon"
            />
          </Form.Group>
          {/* <MDInput
          placeholder="Text"
          value={icon}
          size="small"
          fullWidth
          onChange={(e) => setIcon(e.target.value)}
        /> */}
        </MDBox>

        <MDBox width="100%">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Rank:
          </MDTypography>
          <MDInput
            placeholder="Text"
            type="number"
            value={rank}
            size="small"
            fullWidth
            onChange={(e) => setRank(e.target.value)}
          />
        </MDBox>

        <MDBox mt={1} width="80%">
          <div className="d-grid gap-2">
            <Button variant="primary" onClick={addCategory}>
              Save
            </Button>
          </div>
        </MDBox>
      </>
    </Card>
  );
}

export default AddCategory;
