import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable/index";
import { useNavigate } from "react-router-dom";

import UserTable from "layouts/Users/data/UserTable";

import LoadingAnimation from "components/Animation/LoadingAnimation";

function Users() {
  const {
    columns,
    rows,
    data,
    loading,
    search,
    setSearch,
    pageLimit,
    limit,
    setLimit,
    count,
    setCount,
    total,     
    // onClickFn,
  } = UserTable();
  const navigate = useNavigate();
  const onClickFn = (user) => {
    navigate(`/profile/${user._id}`);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={2}
                  py={1.5}
                  px={1.5}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MDTypography variant="h5" color="white">
                    Users Table
                  </MDTypography>
                </MDBox>
                <MDBox>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                      pagination={true}
                      canSearch={true}
                      search={search}
                      setSearch={setSearch}
                      pageLimit={pageLimit}
                      limit={limit}
                      setLimit={setLimit}
                      datas={data}
                      count={count}
                      total={total}
                      setCount={setCount}
                      onClickFn={onClickFn}
                    />
                  ) : (
                    <LoadingAnimation />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Users;
