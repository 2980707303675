import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Checkbox, IconButton, TextField } from "@mui/material";
import axios from "axios";
import { baseURl } from "BaseUrl";

function PlanDetail({ plan, planUpdated }) {
  const [showDetail, setShowDetail] = useState(true);
  console.log("planUpdated", planUpdated);
  const [name, setName] = useState(plan.name);
  // const [price, setPrice] = useState(plan?.monthly?.unit_amount / 100);
  const [image, setImage] = useState(plan?.metadata?.image_count_left);
  const [text, setText] = useState(plan?.metadata?.text_count_left);
  const [appleId, setAppleId] = useState(plan?.product.apple_product_id);
  const [active, setActive] = useState(plan?.active?.toString());
 

  const editPlan = () => {
    setShowDetail(false);
  };

  const updatePlan = async () => {
    let data = {
      plan_name: name,
      // price,
      image_count_left: image,
      text_count_left: text,
      active: active == "true",
      apple_product_id:appleId
    };
    // planUpdated();
    try {
      const res = await axios.put(
        `${baseURl}/plan/admin/update/${plan.id}`,
        data
      );
      planUpdated();
      // const allPlans = res.data.products;
      // setData(allPlans);

      // setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        padding: "10px",
      }}
    >
      {showDetail ? (
        <>
          <MDBox>
            <Button variant="primary" onClick={editPlan}>
              Edit
            </Button>
          </MDBox>

          <MDBox mt={1}>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Name:
            </MDTypography>
            <MDTypography
              ml={1}
              variant="button"
              fontWeight="bold"
              color="text"
              textTransform="capitalize"
            >
              {plan.name}
            </MDTypography>
          </MDBox>
          <MDBox mt={1} lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Price:
            </MDTypography>
            <MDTypography
              variant="button"
              fontWeight="bold"
              color="text"
              ml={1}
            >
              {plan?.monthly?.unit_amount
                ? parseInt(plan?.monthly?.unit_amount / 100)
                : "-"}
            </MDTypography>
          </MDBox>
          <MDBox mt={1} lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Text Count:
            </MDTypography>
            <MDTypography
              variant="button"
              fontWeight="bold"
              color="text"
              ml={1}
            >
              {plan?.metadata?.text_count_left}
            </MDTypography>
          </MDBox>
          <MDBox mt={1} lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Image Count:
            </MDTypography>
            <MDTypography
              variant="button"
              fontWeight="bold"
              color="text"
              ml={1}
            >
              {plan?.metadata?.image_count_left}
            </MDTypography>
          </MDBox>
          <MDBox mt={1} lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              IsActive:
            </MDTypography>
            <MDTypography
              variant="button"
              fontWeight="bold"
              color="text"
              ml={1}
            >
              {plan?.active ? "Active" : "Inactive"}
            </MDTypography>
          </MDBox>
          <MDBox mt={1} lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Features:
            </MDTypography>
            {plan?.features?.map((cell) => (
              <>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color="text"
                  ml={1}
                >
                  {cell.feat_en}
                </MDTypography>
                <br />
              </>
            ))}
          </MDBox>
        </>
      ) : (
        <>
          <MDBox width="100%">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Plan name:
            </MDTypography>
            <MDInput
              placeholder="Name"
              value={name}
              size="small"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </MDBox>

          {/* <MDBox width="80%">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Plan price:
            </MDTypography>
            <MDInput
              placeholder="Price"
              value={price}
              size="small"
              fullWidth
              onChange={(e) => setPrice(e.target.value)}
            />
          </MDBox> */}

          <MDBox width="100%">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Text count:
            </MDTypography>
            <MDInput
              placeholder="Text"
              value={text}
              size="small"
              fullWidth
              onChange={(e) => setText(e.target.value)}
            />
          </MDBox>

          <MDBox width="100%">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Image count:
            </MDTypography>
            <MDInput
              placeholder="Search..."
              value={image}
              size="small"
              fullWidth
              onChange={(e) => setImage(e.target.value)}
              // onChange={(({ currentTarget }) => {
              //   setSearch(search);)
              //   // onSearchChange(currentTarget.value);
              // }}
            />
          </MDBox>

          <MDBox width="100%">
            <MDTypography variant="button" fontWeight="regular" color="text">
             Apple_ID
            </MDTypography>
            <MDInput
              placeholder="RevenueCat ID"
              value={appleId}
              size="small"
              fullWidth
              onChange={(e) => setAppleId(e.target.value)}
            />
          </MDBox>

          <MDBox width="100%">
            <MDTypography variant="button" fontWeight="regular" color="text">
              Is active:
            </MDTypography>
            <Form.Select
              value={active}
              onChange={(e) => setActive(e.target.value)}
              aria-label="Default select example"
            >
              <option>Choose from list</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </Form.Select>
          </MDBox>

          <MDBox mt={1} width="100%">
            <div className="d-grid gap-2">
              <Button variant="primary" onClick={updatePlan}>
                Save
              </Button>
            </div>
          </MDBox>
        </>
      )}
    </Card>
  );
}

export default PlanDetail;
