import BaseURL from "./baseUrl";

const getAll = (status = "", page = 1, limit = 15) => {
  return BaseURL.get(`/plans/category/get`);
};

const editCategory = (data, id) => {
  return BaseURL.putForm(`/plans/category/update/${id}`, data);
};

const addCategory = (data) => {
  return BaseURL.postForm(`/plans/category/create`, data);
};

const deleteCategory = (id) => {
  return BaseURL.delete(`/plans/category/delete/${id}`);
};

const getUserCourses = (count, limit) => {
  return BaseURL.get(`/user-course?page=${count}&limit=${limit}`);
};
// const allCourse = (count, limit) => {
//   return BaseURL.get(`/course/get?page=${count}&limit=${limit}`);
// };
const Category = {
  getAll,
  editCategory,
  addCategory,
  deleteCategory,
  getUserCourses
};

export default Category;
