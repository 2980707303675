import { CircularProgress } from "@mui/material";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import logo from "assets/images/Aai-logo.png";
import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import axios from "axios";
import { baseURl } from "BaseUrl";
import BasicLayout from "layouts/authentication/basic-layout";

import bgImage from "assets/images/login-bg.jpg";
import { Avatar, TextField } from "@mui/material";
import AuthApi from '../../../apis/auth.ts';

function Basic() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let user = await AuthApi.login(email, password);
      navigate("/new-dashboard");
      setLoading(false);

      return response.data;
    } catch (err) {
      console.log(err), "error";
      setLoading(false);
      setErrorSB(true);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Invalid username / password"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
      <Card
        sx={{
          background: "transparent",
          border: "2px solid rgba(255,255,255,0.5)",
          backdropFilter: "blur(10px)",
        }}
      >
        <MDBox mx={2} mt={2} p={2} mb={1} textAlign="center">
          <Typography
            variant="h4"
            fontWeight="medium"
            sx={{
              color: "#ffff",
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
            }}
            mt={1}
          >
            <img src={logo} width={40} />
            Admin Dashboard
          </Typography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <TextField
                value={email}
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={password}
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={handleSubmit}
              >
                {loading ? <CircularProgress size={20} /> : "Log in"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
