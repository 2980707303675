import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import { useNavigate } from "react-router-dom";
import SubscriberTable from "layouts/Subscribers/data/SubscriberTable";
import TeamTable from "layouts/team/data/TeamTable"
import { useParams } from "react-router-dom";

function Team() {
  const params = useParams();
  const {
    columns,
    rows,
    data,
    loading,
    search,
    setSearch,
    pageLimit,
    limit,
    setLimit,
    count,
    setCount,
    total,
  } = TeamTable();
  const navigate = useNavigate();
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={2}
                py={1.5}
                px={1.5}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h5" color="white">
                 Team Members
                </MDTypography>
              </MDBox>
              <MDBox>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    pagination={true}
                    pageLimit={pageLimit}
                    limit={limit}
                    setLimit={setLimit}
                    datas={data}
                    count={count}
                    total={total}
                    setCount={setCount}
                   
                  />
                ) : (
                  <LoadingAnimation />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Team;