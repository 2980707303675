import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import CourseFeature from "apis/courseFeatures";
import Course from "apis/courses.js";
import MDAvatar from "components/MDAvatar";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CourseVideo from "apis/courseVideo"
import VideoPopup from '../../VideoPopUp';
export default function data() {
    const navigate = useNavigate();
    const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const params = useParams()
    const [successSB, setSuccessSB] = useState(false);
    const [datas, setDatas] = useState([])
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [pageLimit, setPageLimit] = useState();
    const [count, setCount] = useState(1);
    const [total, setTotal] = useState();
    useEffect(() => {
        if (params?.id && data.length === 0) {
            fetchBlog(params?.id)
        }
    }, [params])

    const fetchBlog = async (id) => {
        try {
            const res = await Course.getCourseById(id);
            setLoading(false);
            setDatas(res?.data?.data?.videos)
            setPageLimit(Math.floor(res?.data?.data?.totalDocs / limit + 1));
            setTotal(res?.data?.data?.totalDocs);
        } catch (err) {
            setLoading(false);
            setSuccessSB(true)
            setMessage("SomeThing Went Wrong")
            setTitle("error");
        }
    }
    // Function to open the video popup
    const openVideoPopup = (videoUrl) => {
        setIsVideoPopupOpen(true);
    };

    // Function to close the video popup
    const closeVideoPopup = () => {
        setIsVideoPopupOpen(false);

    };



    const deleteCourse = async (id) => {
        var result = confirm("Are you sure. Do you want to delete?");
        setRefresh(refresh + 1);
        if (result) {
            try {
                const res = await CourseVideo.deleteVideos(id);
                if(res.data){
                setAlert(true);
                setMessage("Feature Deleted Successfully");
                setTitle("success");
                }
                
                // courseData();
            } catch (err) {
                setAlert(true);
                setMessage("Something went wrong");
                setTitle("error");
            }
        }
    };
    // courseData();
    return {
        columns: [
            { Header: "Title ", accessor: "title", align: "left", width: "20%" },
            { Header: "Thumbnail", accessor: "icon", align: "center" },
            // { Header: "Video", accessor: "video", align: "center" },
            { Header: "Time", accessor: "time", align: "center" },
            { Header: "Action", accessor: "action", align: "center" },
        ],

        rows: datas?.map((course) => ({
            row: course,
            title: (<>
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    sx={{ display: 'inline-block', maxWidth: "250px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {course?.title?.en || "N/A"}
                </MDTypography>
                <br />
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    sx={{ display: 'inline-block', maxWidth: "250px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {course?.title?.ar || "N/A"}
                </MDTypography>
            </>
            ),

            icon: (<>
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                >
                    <img style={{ width: "30px" }} src={course?.thumbnail || "N/A"} />
                </MDTypography>
            </>
            ),
            // video: (<>
            //     <MDTypography
            //         component="a"
            //         href="#"
            //         variant="caption"
            //         color="text"
            //         fontWeight="medium"
            //         onClick={() => setShowModal(true)}
            //     >
            //         <div> {course?.video}</div>

            //     </MDTypography>
            //     <VideoPopup
            //         videoUrl={course?.video}
            //         setShowModal={setShowModal} showModal={showModal}
            //     />
            // </>
            // ),
            time: (<>
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                >
                    Created At: {moment(course?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
                </MDTypography>
                <br />
                <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                >
                    Updated At: {moment(course?.updatedAt).format("DD/MM/YYYY,h:mm:ss a")}

                </MDTypography>
            </>
            ),
            // createdAt: (
            //     <MDTypography
            //         component="a"
            //         href="#"
            //         variant="caption"
            //         color="text"
            //         fontWeight="medium"
            //     >
            //         {moment(course?.createdAt).format("DD/MM/YYYY,h:mm:ss a") || "N/A"}
            //     </MDTypography>
            // ),
            action: (
                <div className="d-flex gap-5">
                    <Button
                        variant="danger"
                        onClick={() => {
                            deleteCourse(course._id);
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            navigate(`/update-coursevideo/${course._id}`)
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ),
        })),
        data: data,
        loading: loading,
        alert,
        setAlert,
        message,
        refresh,
        setRefresh,
        pageLimit: pageLimit,
        limit: limit,
        setLimit: setLimit,
        count: count,
        setCount: setCount,
        total: total
    };
}
