import React, { Fragment, useEffect, useState } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Form from "react-bootstrap/Form";
import Rte from "components/BlogNote/Rte";
import { Button, Modal } from "react-bootstrap";
import Blog from "apis/blog";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from 'components/Animation/LoadingAnimation';
import MDSnackbar from 'components/MDSnackbar';

const EditBlog = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [blogData, setBlogData] = useState();
    const [titleEn, setTitleEN] = useState(blogData?.title_en || "");
    const [titleAr, setTitleAr] = useState(blogData?.title_ar || "");
    const [shortDescriptionEn, setShortDescriptionEn] = useState(blogData?.description_en || "");
    const [shortDescriptionAr, setShortDescriptionAr] = useState(blogData?.description_ar || "");
    const [cover, setCover] = useState();
    const [coverAltEn, setCoverAltEn] = useState(blogData?.cover_alt_en || "");
    const [coverAltAr, setCoverAltAr] = useState(blogData?.cover_alt_ar || "");
    const [banner, setBanner] = useState();
    const [bannerAltEn, setBannerAltEn] = useState(blogData?.banner_alt_en || "");
    const [bannerAltAr, setBannerAltAr] = useState(blogData?.banner_alt_ar || "");
    const [metaDescriptionEn, setMetaDescriptionEn] = useState(blogData?.meta_description_en || "");
    const [metaDescriptionAr, setMetaDescriptionAr] = useState(blogData?.metaDescriptionAr || "");
    const [contentEn, setContentEn] = useState(blogData?.content_en || "");
    const [contentAr, setContentAr] = useState(blogData?.content_ar || "");
    const [errors, setErrors] = useState();
    const [loading, setLoading] = useState(true);
    const [successSB, setSuccessSB] = useState(false);
    const [message, setMessage] = useState();
    const [title, setTitle] = useState();
    const [showImage, setShowImage] = useState(false);
    const [type, setType] = useState("");

    useEffect(() => {
        if (blogData) {
            setTitleEN(blogData?.title_en || "")
            setTitleAr(blogData?.title_ar || "")
            setShortDescriptionEn(blogData?.description_en || "")
            setShortDescriptionAr(blogData?.description_ar || "")
            setCoverAltEn(blogData?.cover_alt_en || "")
            setCoverAltAr(blogData?.cover_alt_ar || "")
            setBannerAltEn(blogData?.banner_alt_en || "")
            setBannerAltAr(blogData?.banner_alt_ar || "")
            setMetaDescriptionEn(blogData?.meta_description_en || "")
            setMetaDescriptionAr(blogData?.meta_description_ar || "")
            setContentEn(blogData?.content_en || "")
            setContentAr(blogData?.content_ar || "")
        }
    }, [blogData])

    useEffect(() => {
        if (params?.id && !blogData) {
            fetchBlog(params?.id)
        }
    }, [params])

    const fetchBlog = async (id) => {
        try {
            const res = await Blog.getBlogById(id);
            setLoading(false);
            setBlogData(res?.data?.blog)
        } catch (err) {
            setLoading(false);
            setSuccessSB(true)
            setMessage("SomeThing Went Wrong")
            setTitle("error");
        }
    }

    const updateBlog = async () => {
        setLoading(true);
        setErrors(false);
        let data = {
            title_en: titleEn,
            title_ar: titleAr,
            description_en: shortDescriptionEn,
            description_ar: shortDescriptionAr,
            cover_alt_en: coverAltEn,
            cover_alt_ar: coverAltAr,
            banner_alt_en: bannerAltEn,
            banner_alt_ar: bannerAltAr,
            meta_description_en: metaDescriptionEn,
            meta_description_ar: metaDescriptionAr,
            content_en: contentEn,
            content_ar: contentAr,
            publishedAt: new Date()
        };
        if (cover) {
            data.cover_image = cover;
        }
        if (banner) {
            data.banner_image = banner;
        }
        if (
            !data.title_en ||
            !data.title_ar ||
            !data.description_en ||
            !data.description_ar ||
            !data.cover_alt_en ||
            !data.cover_alt_ar ||
            !data.banner_alt_en ||
            !data.banner_alt_ar ||
            !data.meta_description_en ||
            !data.meta_description_ar ||
            !data.content_en ||
            !data.content_ar
        ) {
            setErrors(true);
            setLoading(false);
            window.scrollTo(0, 0);
        } else {
            try {
                const res = await Blog.updateBlog(blogData?._id, data);
                clearData();
                setLoading(false);
                setSuccessSB(true);
                setMessage("Blog Update successfully");
                setTitle("success");
            } catch (err) {
                setLoading(false);
                setSuccessSB(true);
                setMessage("SomeThing Went Wrong");
                setTitle("error");
            }
        }
    };

    const clearData = () => {
        setBlogData("")
        setTitleEN("");
        setTitleAr("");
        setShortDescriptionEn("");
        setShortDescriptionAr("");
        setCover("");
        setCoverAltEn("");
        setCoverAltAr("");
        setBanner("");
        setBannerAltEn("");
        setBannerAltAr("");
        setMetaDescriptionEn("");
        setMetaDescriptionAr("");
        setContentEn("");
        setContentAr("");
    }
    const handleFileChange = (e) => {
        if (e.target.files) {
            setCover(e.target.files[0]);
        }
    };

    const handleFileChangeBanner = (e) => {
        if (e.target.files) {
            setBanner(e.target.files[0]);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <MDSnackbar
                color={title}
                icon="check"
                title={title}
                content={message}
                open={successSB}
                onClose={() => setSuccessSB(false)}
                close={() => setSuccessSB(false)}
            />
            {loading ? (
                <LoadingAnimation />
            ) : (
                <Fragment>
                    <MDBox position="relative" mb={5}>
                        <Card
                            sx={{
                                position: "relative",
                                mt: 2,
                                // mx: 3,
                                py: 2,
                                px: 2,
                            }}
                        >
                            <Grid container spacing={6} alignItems="center">

                                <Grid item lg={4} md={6} sm={12} width="100%">
                                    <MDBox height="100%" mt={0.5} lineHeight={1} >
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Title (en):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Title"
                                            value={titleEn}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setTitleEN(e.target.value)}
                                        />
                                        {errors && (titleEn === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                    <MDBox height="100%" mt={0.5} lineHeight={1} >
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Title (ar):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Name (AR)"
                                            value={titleAr}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setTitleAr(e.target.value)}
                                        />
                                        {errors && (titleAr === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} width="100%">
                                    <MDBox height="100%" mt={0.5} lineHeight={1} >
                                        <MDTypography sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} variant="button" fontWeight="regular" color="text">
                                            Cover Image (400 x 284):
                                            {!cover && (
                                                <Link onClick={() => { setShowImage(true); setType("COVER") }}>View Image</Link>
                                            )}
                                        </MDTypography>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                onChange={handleFileChange}
                                                type="file"
                                                placeholder="choose cover"
                                            />
                                        </Form.Group>
                                        {errors && (cover === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                    <MDBox height="100%" mt={0.5} lineHeight={1} >
                                        <MDTypography sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} variant="button" fontWeight="regular" color="text">
                                            Banner Image(1209 x 437):
                                            {!banner && (
                                                <Link onClick={() => { setShowImage(true); setType("BANNER") }}>View Image</Link>
                                            )}
                                        </MDTypography>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                onChange={handleFileChangeBanner}
                                                type="file"
                                                placeholder="choose cover"
                                            />
                                        </Form.Group>
                                        {errors && (banner === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} width="100%">
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Cover Alt (en):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Alt"
                                            value={coverAltEn}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setCoverAltEn(e.target.value)}
                                        />
                                        {errors && (coverAltEn === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Cover Alt (ar):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Alt (AR)"
                                            value={coverAltAr}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setCoverAltAr(e.target.value)}
                                        />
                                        {errors && (coverAltAr === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>

                                </Grid>
                                <Grid item lg={4} md={6} sm={12} width="100%">
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Banner Alt (en):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Alt"
                                            value={bannerAltEn}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setBannerAltEn(e.target.value)}
                                        />
                                        {errors && (bannerAltEn === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Banner Alt (ar):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Alt (AR)"
                                            value={bannerAltAr}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setBannerAltAr(e.target.value)}
                                        />
                                        {errors && (bannerAltAr === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} width="100%">
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Short Description (en):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Description"
                                            value={shortDescriptionEn}
                                            multiline
                                            rows={7}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setShortDescriptionEn(e.target.value)}
                                        />
                                        {errors && (shortDescriptionEn === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Short Description (ar):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Description (AR)"
                                            value={shortDescriptionAr}
                                            multiline
                                            rows={7}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setShortDescriptionAr(e.target.value)}
                                        />
                                        {errors && (shortDescriptionAr === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} width="100%">
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Meta Description (en):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Meta Description"
                                            value={metaDescriptionEn}
                                            multiline
                                            rows={7}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setMetaDescriptionEn(e.target.value)}
                                        />
                                        {errors && (metaDescriptionEn === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Meta Description (ar):
                                        </MDTypography>
                                        <MDInput
                                            placeholder="Meta Description (AR)"
                                            value={metaDescriptionAr}
                                            multiline
                                            rows={7}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setMetaDescriptionAr(e.target.value)}
                                        />
                                        {errors && (metaDescriptionAr === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} width="100%">
                                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Content (en):
                                        </MDTypography>
                                        <Rte content={contentEn} setContent={setContentEn} />
                                        {errors && (contentEn === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                    <MDBox height="100%" mt={5} lineHeight={1}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Content (ar):
                                        </MDTypography>
                                        <Rte content={contentAr} setContent={setContentAr} />
                                        {errors && (contentAr === "") && (
                                            <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                                Required!
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </Grid>
                                <Grid item lg={4} md={4} />
                                <Grid item lg={4} md={4} sm={12} width="100%">
                                    <MDBox mt={1}>
                                        <div className="d-grid gap-2">
                                            <Button variant="primary" onClick={updateBlog}>
                                                Save
                                            </Button>
                                        </div>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </Card>
                    </MDBox>
                    <Modal style={{ paddingTop: "5%" }} show={showImage} onHide={() => setShowImage(false)} >
                        <Modal.Header>
                            <Modal.Title>
                                Image
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img style={{ width: "100%" }} src={type === "COVER" ? blogData?.cover_image : blogData?.banner_image} alt={type === "COVER" ? blogData?.cover_alt_en : blogData?.banner_alt_en} />
                        </Modal.Body>

                    </Modal>
                </Fragment>
            )}

        </DashboardLayout>
    )
}

export default EditBlog