import Grid from "@mui/material/Grid";
import { useState } from "react";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import LoadingAnimation from "components/Animation/LoadingAnimation";

import PlanTable from "layouts/Plans/data/PlanTable";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import PlanDetails from "./data/PlanDetail";
import AddPlan from "./data/AddPlan";

function Users() {
  var { columns, rows, data, loading, refresh, setRefresh } = PlanTable();

  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [value, setValue] = useState(0);

  const showPlanModal = (plan) => {
    console.log("plan", plan);
    setSelectedPlan(plan);
    setShow(true);
  };

  const showAddPlan = () => {
    setShowAdd(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };

  const planUpdated = () => {
    // { columns, rows, data, loading } = PlanTable();
    // rows = PlanTable().rows;
    setShow(false);
    setShowAdd(false);
    setRefresh(refresh + 1);
    // window.location.reload();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={2}
                py={1.5}
                px={1.5}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h5" color="white">
                  Plans
                </MDTypography>
                <Button
                  onClick={() => {
                    showAddPlan();
                  }}
                  variant="danger"
                >
                  Create
                </Button>
              </MDBox>
              <MDBox>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    pagination={false}
                    canSearch={false}
                    datas={data}
                    onClickFn={showPlanModal}
                  />
                ) : (
                  <LoadingAnimation />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PlanDetails plan={selectedPlan} planUpdated={planUpdated} />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            style={{ width: "50%" }}
            size="sm"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showAdd} onHide={handleCloseAdd}>
        <Modal.Header>
          <Modal.Title>Add Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPlan planUpdated={planUpdated} />
        </Modal.Body>
      </Modal>
    </DashboardLayout>
  );
}

export default Users;
