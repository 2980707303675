import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { baseURl } from "BaseUrl";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Typography, Tooltip } from "@mui/material";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Box, Button } from "@mui/material";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import MDBadge from "components/MDBadge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";

const Images = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);

  //memoised data
  const memoizedData = useMemo(() => data, [data]);

  const userData = async () => {
    setLoad(true);
    try {
      const res = await axios.get(`${baseURl}/textGeneration/getGeneratedImages?page=${count}`);
      const allUsers = res.data.data;
      setData([...data, ...allUsers]);
      setLoading(false);
      setLoad(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userData();
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (
  //       !loading &&
  //       !load &&
  //       window.innerHeight + document.documentElement.scrollTop ===
  //         document.documentElement.offsetHeight
  //     ) {
  //       setCount((prevPage) => prevPage + 1);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [loading, load]);

  return (
    <>
      {!loading ? (
        <DashboardLayout>
          <DashboardNavbar />

          {memoizedData?.map((items, index) => {
            return (
              <>
                <Card sx={{ mt: 2 }}>
                  <Box py={2} px={2} lineHeight={1.25} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/profile/${items?.user?._id}`)}
                    >
                      <Box mr={2}>
                        <MDAvatar src={items?.user?.image} alt="profile-image" size="lg" shadow="sm" />
                      </Box>
                      <Grid container spacing={2}>
                        <Grid xs={4}>
                          <Typography variant="h6" fontWeight="500">
                            {items?.user?.name}
                          </Typography>
                          <Typography variant="h6" fontWeight="500">
                            {items?.user?.email}
                            {items?.user?.email_verified_at || items?.user?.plan_start ? (
                              <Tooltip title={items?.user?.plan_start ? "subscriber" : "verified"}>
                                <MDBadge badgeContent=<CheckCircleIcon /> color={items?.user.plan_start ? "info" : "success"} variant="gradient" size="sm" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="not verified">
                                <MDBadge badgeContent=<CancelIcon /> color="error" variant="gradient" size="sm" />
                              </Tooltip>
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={8}>
                          <MDBox mb={1}>
                            <MDTypography variant="h6" color="text" fontWeight="200">
                              {`Prompts : ${items.prompt}`}
                            </MDTypography>
                            <MDTypography variant="button" color="text">
                              {moment(items.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <MDBox p={2}>
                    <Grid container spacing={6}>
                      {items?.urls?.map((images, index) => {
                        return (
                          <Grid item xs={12} md={6} xl={3}>
                            <DefaultProjectCard image={images} label={index + 1} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </MDBox>
                </Card>
              </>
            );
          })}
          <Button
            variant="contained"
            color="white"
            sx={{
              ml: "auto",
              my: 2,
            }}
            onClick={() => {
              setCount((prevPage) => prevPage + 1);
              userData();
            }}
          >
            Load More
            {load && (
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-dasharray="15" stroke-dashoffset="15" stroke-linecap="round" stroke-width="2" d="M12 3C16.9706 3 21 7.02944 21 12">
                  <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.3s" values="15;0" />
                  <animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                </path>
              </svg>
            )}
          </Button>
        </DashboardLayout>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};

export default Images;
