// import Dashboard from "layouts/Dashboard";
import Dashboard from "layouts/dashboard";
import NewDashboard from "layouts/newDashboard";
import Users from "layouts/Users";
import Subscribers from "layouts/Subscribers";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Team from "layouts/team";
import SignIn from "layouts/authentication/sign-in";
import Plans from "layouts/Plans";
import Survey from "layouts/Survey";
import CreateCourse from "layouts/CoursesMain/Courses/CreateCourse";
import Cards from "layouts/CoursesMain/Courses/Cards";
import CourseCategory from "layouts/CoursesMain/CourseCategory";
import CreateCourseCategory from "layouts/CoursesMain/CourseCategory/CreateCourseCategory";
import UpdateCourseCategory from "layouts/CoursesMain/CourseCategory/UpdateCreateCourseCategory";
import CreateCourseSkill from "layouts/CoursesMain/CourseSkill/CreateSkill";
import CreateCourseFeature from "layouts/CoursesMain/CourseFeatures/CreateFeature";
import CreateCourseInstructor from "layouts/CoursesMain/CourseInstructors/CreateInstructor";
import CreateCourseVideo from "layouts/CoursesMain/CourseVideo/CreateVideo";
import CourseFeature from "layouts/CoursesMain/CourseFeatures";
import UpdateFeature from "layouts/CoursesMain/CourseFeatures/UpdateFeature";
import UpdateInstructor from "layouts/CoursesMain/CourseInstructors/UpdateInstructor";
import UpdateSkill from "layouts/CoursesMain/CourseSkill/UpdateSkill";
import CourseVideo from "layouts/CoursesMain/CourseVideo";
import CourseInstructor from "layouts/CoursesMain/CourseInstructors/";
import UserCourse from "layouts/CourseUser";
import ViewCourseVideo from "layouts/CoursesMain/CourseVideo/ViewAllVideo";
import UpdateVideo from "layouts/CoursesMain/CourseVideo/UpdateVideo";
// @mui icons
import Icon from "@mui/material/Icon";
import Images from "layouts/Generated/Images";
import Texts from "layouts/Generated/Texts";
import Enterprise from "layouts/Enterprise";
import Category from "layouts/Category";
import Newsletter from "layouts/Newsletter/Newsletter";
import Blog from "layouts/Blog";
import Courses from "layouts/CoursesMain/Courses";
import CreateBlog from "layouts/Blog/CreateBlog";
import EditBlog from "layouts/Blog/UpdateBlog";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
import PriceCheckRoundedIcon from "@mui/icons-material/PriceCheckRounded";
import PollIcon from "@mui/icons-material/Poll";
import Groups2Icon from "@mui/icons-material/Groups2";
import EditCourse from "layouts/CoursesMain/Courses/UpdateCourse";
import CourseSkill from "layouts/CoursesMain/CourseSkill";
import ImportContactsRoundedIcon from "@mui/icons-material/ImportContactsRounded";
import UseCase from "layouts/UseCase/Index";
import ArticleIcon from "@mui/icons-material/Article";

import Affilliate from "layouts/affilliate";
import AffilliateById from "layouts/affilliate/data/affiliateById";
import Webinar from "layouts/Webinar";

const routes = [
  {
    type: "collapse",
    name: "New Analytics",
    key: "new-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/new-dashboard",
    component: <NewDashboard />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <PeopleRoundedIcon />,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Subscribers",
    key: "subscriber",
    icon: <WorkspacePremiumIcon />,
    route: "/subscriber",
    component: <Subscribers />,
  },
  {
    type: "collapse",
    name: "Survey",
    key: "survey",
    icon: <PollIcon />,
    route: "/survey",
    component: <Survey />,
  },
  {
    type: "collapse",
    name: "Webinar",
    key: "webinar",
    icon: <Groups2Icon />,
    route: "/webinar",
    component: <Webinar />,
  },
  {
    type: "collapse",
    name: "Enterprises",
    key: "enterprise",
    icon: <BusinessCenterRoundedIcon />,
    route: "/enterprise",
    component: <Enterprise />,
  },
  {
    type: "collapse",
    name: "Affilliates",
    key: "affilliate",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/affilliate",
    component: <Affilliate />,
  },

  {
    type: "collapse",
    name: "Courses",
    key: "courses",
    icon: <ImportContactsRoundedIcon />,
    route: "/courses",
    component: <Cards />,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "category",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/category",
    component: <Category />,
  },
  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    icon: <PriceCheckRoundedIcon />,
    route: "/plans",
    component: <Plans />,
  },

  {
    type: "collapse",
    name: "Blog",
    key: "blog",
    icon: <RssFeedRoundedIcon />,
    route: "/blog",
    component: <Blog />,
  },

  {
    type: "title",
    route: "/viewcourses",
    component: <Courses />,
  },
  {
    type: "title",
    route: "/update-coursevideo/:id",
    component: <UpdateVideo />,
  },
  {
    type: "title",
    route: "/viewcourse-video/:id",
    component: <ViewCourseVideo />,
  },
  {
    type: "title",
    route: "/view-user-courses",
    component: <UserCourse />,
  },
  {
    type: "title",
    route: "/edit-instructor/:id",
    component: <UpdateInstructor />,
  },
  {
    type: "title",
    route: "/courseinstructor",
    component: <CourseInstructor />,
  },
  {
    type: "title",
    route: "/create-coursevideo/:id",
    component: <CreateCourseVideo />,
  },
  {
    type: "title",
    route: "/create-coursevideo",
    component: <CreateCourseVideo />,
  },
  {
    type: "title",
    route: "/create-courseinstructor",
    component: <CreateCourseInstructor />,
  },
  {
    type: "title",
    route: "/coursevideo",
    component: <CourseVideo />,
  },
  {
    type: "title",
    route: "/edit-feature/:id",
    component: <UpdateFeature />,
  },
  {
    type: "title",
    route: "/edit-skill/:id",
    component: <UpdateSkill />,
  },
  {
    type: "title",
    route: "/create-coursefeature",
    component: <CreateCourseFeature />,
  },
  {
    type: "title",
    route: "/course-features",
    component: <CourseFeature />,
  },
  {
    type: "title",
    route: "/coursecategory",
    component: <CourseCategory />,
  },
  {
    type: "title",
    route: "/courseskill",
    component: <CourseSkill />,
  },
  {
    type: "title",
    route: "/create-courseskill",
    component: <CreateCourseSkill />,
  },

  {
    type: "title",
    route: "/createcoursecategory",
    component: <CreateCourseCategory />,
  },
  {
    type: "title",
    route: "/update-coursecategory/:id",
    component: <UpdateCourseCategory />,
  },

  {
    type: "collapse",
    name: "Use-case",
    key: "useCase",
    icon: <ArticleIcon />,
    route: "/useCase",
    component: <UseCase />,
  },
  {
    type: "title",
    route: "/add-blog",
    component: <CreateBlog />,
  },
  {
    type: "title",
    route: "/add-course",
    component: <CreateCourse />,
  },
  {
    type: "title",
    route: "/edit-blog/:id",
    component: <EditBlog />,
  },
  {
    type: "title",
    route: "/edit-course/:id",
    component: <EditCourse />,
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  {
    type: "title",
    route: "/images",
    component: <Images />,
  },
  {
    type: "title",
    route: "/texts",
    component: <Texts />,
  },
  {
    type: "title",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile/:id",
    component: <Profile />,
  },
  {
    type: "title",
    icon: <Icon fontSize="small">team</Icon>,
    route: "/enterprise/:id",
    component: <Team />,
  },
  {
    type: "title",
    route: "/affilliate/:id",
    component: <AffilliateById />,
  },

  {
    type: "title",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/Login",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Newsletters",
    key: "newsletter",
    icon: <Icon fontSize="small">mailicon</Icon>,
    route: "/newsletter",
    component: <Newsletter />,
  },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
];

export default routes;
