import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDAvatar from "components/MDAvatar";
import { Grid, Stack, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import DataTable from "examples/Tables/DataTable";
import affilliatedUsers from "./affilliatedUsers";

function AffilliateById() {
  var { columns, rows, data, loading, refresh, setRefresh, setCount, pageLimit, limit, setLimit, count, total, codeData, setShowPayment, showPayment } = affilliatedUsers();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
          padding: "10px",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={2} mt={1}>
          <MDAvatar src={codeData?.user_id?.image} size="sm" />
          <MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
              Name: {codeData?.user_id?.name}
            </MDTypography>
            <MDTypography variant="h6" fontWeight="regular" color="text" textTransform="capitalize">
              Email: {codeData?.user_id?.email}
            </MDTypography>
          </MDBox>
        </Stack>
        <Stack sx={{ pl: 7 }}>
          <MDBox mt={1} lineHeight={0}>
            <MDTypography variant="body2" fontWeight="regular" color="text">
              Affilliate code: <b>{codeData?.code}</b>
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular" color="text">
              Current earnings: $ {codeData?.current_earning}
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular" color="text">
              Total earning: $ {codeData?.total_earning}
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular" color="text">
              Usage: {codeData?.usage}
            </MDTypography>
          </MDBox>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={2} justifyContent={"center"}>
          <MDTypography variant="body2" fontWeight="regular" color="text">
            Affilliate Users
          </MDTypography>
          <Switch checked={showPayment} onClick={() => setShowPayment(!showPayment)} />
          <MDTypography variant="body2" fontWeight="regular" color="text">
            Earnings
          </MDTypography>
        </Stack>
      </Card>
      <MDBox mt={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                my={2}
                py={1.5}
                px={1.5}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h5" color="white">
                  {showPayment ? "Payments Requested" : "Affiliated User"}
                </MDTypography>
                <MDButton onClick={() => setRefresh(refresh + 1)} variant="contained" color="info" endIcon={<RefreshRoundedIcon />}>
                  Refresh
                </MDButton>
              </MDBox>
              <MDBox>
                {!loading ? (
                  <DataTable
                    type="AFFILIATE"
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    pagination={true}
                    // search={search}
                    // setSearch={setSearch}
                    canSearch={false}
                    datas={data}
                    pageLimit={pageLimit}
                    limit={limit}
                    setLimit={setLimit}
                    count={count}
                    setCount={setCount}
                    total={total * 10}
                  />
                ) : (
                  <LoadingAnimation />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AffilliateById;
