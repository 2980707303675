import axios from "axios"; 
const getAll = (status = "", page = 1, limit = 15) => {
  return axios.get(`${process.env.REACT_APP_BLOG_URL}admin/blog/newsLetterList`);
   
}; 

const Newsletter = {
  getAll,
};

export default Newsletter;
