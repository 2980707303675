import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const AnsweredSkippedChart = ({ data }) => {
  const chartData = {
    labels: data?.answered_skipped_per_question?.map((item) => item?._id),
    datasets: [
      {
        label: "Answered",
        data: data?.answered_skipped_per_question?.map((item) => item?.totalNonNullAnswers),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Skipped",
        data: data?.answered_skipped_per_question?.map((item) => item?.totalNullAnswers),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  return <Bar data={chartData} />;
};

export default AnsweredSkippedChart;
