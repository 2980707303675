import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import NewsletterTable from "./NewsletterTable";

import axios from "axios";



function Newsletter() { 
  var {
    columns,
    rows,
    data,
    loading,
    refresh,
    setRefresh,
    setCount,
    pageLimit,
    limit,
    setLimit,
    count,
    search,
    setSearch,
  } = NewsletterTable();
  
  const viewNewsLetterList= async() => {
    try{
    const res= await axios.get("https://v1.test.blog.araby.ai/admin/blog/newsLetterList").then
    // setData(res.data.data);
  } catch(err) 
  {
    console.log(err);
  }
}

useEffect(()=>{
  viewNewsLetterList();
}, []);
  return (
    
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={2}
                  py={1.5}
                  px={1.5}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MDTypography variant="h5" color="white">
                    Newsletters
                  </MDTypography>
                </MDBox>
           
   
                <MDBox>
                  {!loading ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                      pagination={true}
                      canSearch={true}
                      search={search}
                      setSearch={setSearch}
                      pageLimit={pageLimit}
                      limit={limit}
                      setLimit={setLimit}
                      datas={data}
                      count={count}
                      setCount={setCount}
                     
                    />
                  ) : (
                    <LoadingAnimation />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
export default Newsletter;



// https://v1.test.blog.araby.ai/admin/blog/newsLetterList