import React, { useState } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Form from "react-bootstrap/Form";
import Rte from "components/BlogNote/Rte";
import { Button } from "react-bootstrap";
import CourseCategory from 'apis/courseCategory';


import LoadingAnimation from 'components/Animation/LoadingAnimation';
import MDSnackbar from 'components/MDSnackbar';
import { useNavigate } from 'react-router-dom';

const CreateBlog = () => {

    const [titleEn, setTitleEN] = useState("");
    const [titleAr, setTitleAr] = useState("");
    const [level, setLevel] = useState("beginner")
    const [title, setTitle] = useState();
    const [message, setMessage] = useState();
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(1);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const navigate = useNavigate()
    const addCourseCategory = async () => {
        setLoading(true);
        setErrors(false);
        let data = {
            "name": ({
                "en": titleEn,
                "ar": titleAr
            }),
            "level": level,
        };
        // let data = {
        //     "name": {
        //         "en": titleEn,
        //         "ar": titleAr
        //     },
        //     "level": level,
        // };
        if (
            !titleEn ||
            !titleAr ||
            !level
        ) {
            setErrors(true);
            setLoading(false);
            window.scrollTo(0, 0);
        } else {
            try {
                const res = await CourseCategory.addCategory((JSON.stringify(data)));
                setLoading(false)
                
                clearData()
                if(res.data){
                setSuccessSB(true)
                setMessage("Course Category Created successfully")
                setTitle("success");
                    setTimeout(() => {
                        navigate("/coursecategory");
                      }, 2000);
                    }
                
            } catch (err) {
                setLoading(false)
                setSuccessSB(true)
                setMessage("SomeThing Went Wrong")
                setTitle("error");
            }
        }
    };

    const clearData = () => {
        setTitleEN("");
        setTitleAr("");
        setLevel("")
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <MDSnackbar
                color={title}
                icon="check"
                title={title}
                content={message}
                open={successSB}
                onClose={() => setSuccessSB(false)}
                close={() => setSuccessSB(false)}
            />
            {loading ? (
                <LoadingAnimation />
            ) : (
                <MDBox position="relative" mb={5}>
                    <Card
                        sx={{
                            position: "relative",
                            mt: 2,
                            // mx: 3,
                            py: 2,
                            px: 2,
                        }}
                    >
                        <Grid container spacing={6} alignItems="center">

                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (en):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Title"
                                        value={titleEn}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleEN(e.target.value)}
                                    />
                                    {errors && (titleEn === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid><Grid item lg={4} md={6} sm={12} width="100%">
                                <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Title (ar):
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Name (AR)"
                                        value={titleAr}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setTitleAr(e.target.value)}
                                    />
                                    {errors && (titleAr === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} width="100%">
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                    Level:
                                </MDTypography><br />
                                <select style={{ width: "120px", border: "1px solid #D3D3D3", borderRadius: "5px", height: "35px", color: "#D3D3D3", outline: "none" }} value={level} onChange={(e)=>setLevel(e.target.value)}>
                                    <option value="beginner">Beginner</option>
                                    <option value="advance">Advance</option>
                                </select>


                                {/* <MDBox height="100%" mt={0.5} lineHeight={1} >
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        Level:
                                    </MDTypography>
                                    <MDInput
                                        placeholder="Level"
                                        value={level}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setLevel(e.target.value)}
                                    />
                                    {errors && (level === "") && (
                                        <MDTypography sx={{ color: "red" }} variant="button" fontWeight="regular" color="text">
                                            Required!
                                        </MDTypography>
                                    )}
                                </MDBox> */}
                            </Grid>

                            <Grid item lg={4} md={4} />
                            <Grid item lg={4} md={4} sm={12} width="100%">
                                <MDBox mt={1}>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" onClick={addCourseCategory}>
                                            Save
                                        </Button>
                                    </div>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Card>
                </MDBox>
            )}
        </DashboardLayout>
    )
}

export default CreateBlog