import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import axios from "axios";
import { baseURl } from "BaseUrl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import noUser from "assets/images/Aai-logo.png";
import { Toolbar, Tooltip } from "@mui/material";
import moment from "moment";

export default function data() {
  const DataAllowance = ({ image, text }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {image}
      </MDTypography>
      <MDTypography variant="caption">{text}</MDTypography>
    </MDBox>
  );

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    planData();
  }, [refresh]);

  const planData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseURl}/plan/user/getAll?active=${true}`);
      const allPlans = res.data.products;
      setData(allPlans);

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  // planData();
  return {
    columns: [
      { Header: "NAME", accessor: "name", align: "left", width: "20%" },
      { Header: "Monthly price", accessor: "price", align: "center" },
      { Header: "Data Allowance", accessor: "data", align: "center" },
      { Header: "Category", accessor: "category", align: "center" },
      { Header: "Created At", accessor: "created", align: "center" },
    ],

    rows: data?.map((plan) => ({
      row: plan,
      name: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {plan?.name}
        </MDTypography>
      ),

      price: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {plan?.monthly?.unit_amount
            ? parseInt(plan?.monthly?.unit_amount / 100)
            : "-"}
        </MDTypography>
      ),

      data: (
        <DataAllowance
          text={`Text : ${
            plan?.metadata?.text_count_left
              ? plan?.metadata?.text_count_left
              : 0
          }`}
          image={`Image: ${
            plan?.metadata?.image_count_left
              ? plan?.metadata?.image_count_left
              : 0
          }`}
        />
      ),
      category: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {plan?.product?.category?.name_en}
        </MDTypography>
      ),
      created: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(plan?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
        </MDTypography>
      ),
    })),
    data: data,
    loading: loading,
    refresh,
    setRefresh,
  };
}
