import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import configs from "./configs/index";

function ReportsBarChart({ color, chart, graph, types, flag }) {
  const [updateFlag, setUpdateFlag] = useState(false);
  const { options } = configs(chart || [], chart || {});

  const data = {
    labels: graph?.map((row) => row.useCase || row.category),
    datasets: [
      {
        label: "use case count",
        data: graph?.map((row) => row.total || row.count),
        backgroundColor: "#ffffff",
      },
    ],
  };

  // Re-render the chart when flag changes
  useEffect(() => {
    setUpdateFlag(!updateFlag);
  }, [flag]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        <MDBox variant="gradient" bgColor={color} borderRadius="lg" coloredShadow={color} py={2} pr={0.5} height="20.5rem">
          <Bar key={updateFlag} data={data} options={options} />
        </MDBox>
      </MDBox>
    </Card>
  );
}

ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
};

ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
