import BaseURL from "./baseUrl";
// All apis for course categories
const allCategory = (count, limit) => {
    return BaseURL.get(`/course-category/get?page=1&limit=10`);
};

const addCategory = (data) => {
    return BaseURL.post(`course-category/add`, data);
};

const deleteCategory = (id) => {
    return BaseURL.delete(`/course-category/delete/${id}`);
};

const getCategoryById = (id) => {
    return BaseURL.get(`/course-category/get/${id}`);
}
const updateCategory = (id, data) => {
    return BaseURL.put(`/course-category/edit/${id}`, data);
};

const CourseCategory = {
    allCategory,
    addCategory,
    deleteCategory,
    updateCategory,
    getCategoryById
};
export default CourseCategory