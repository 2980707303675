import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import Category from "apis/category";
import MDAvatar from "components/MDAvatar";
import { Button } from "react-bootstrap";

export default function data() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    categoryData();
  }, [refresh]);

  const categoryData = async () => {
    try {
      setLoading(true);
      const res = await Category.getAll();
      // const res = await axios.get(
      //   `${baseURl}/category/user/getAll?active=${true}`
      // );
      const allcategorys = res.data.categories;
      setData(allcategorys);

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCategory = async (id) => {
    var result = confirm("Are you sure. Do you want to delete?");
    setRefresh(refresh + 1);
    if (result) {
      const res = await Category.deleteCategory(id);
      categoryData();
    }

    //TODO add confirm button
  };
  // categoryData();
  return {
    columns: [
      { Header: "Name En", accessor: "name_en", align: "left", width: "20%" },
      { Header: "Name Ar", accessor: "name_ar", align: "center" },
      { Header: "Description En", accessor: "description_en", align: "center" },
      { Header: "Description Ar", accessor: "description_ar", align: "center" },
      { Header: "Icon", accessor: "icon", align: "center" },
      { Header: "Rank", accessor: "rank", align: "center" },
      { Header: "Created At", accessor: "created", align: "center" },
      { Header: "Actions", accessor: "action", align: "center" },
    ],

    rows: data?.map((category) => ({
      row: category,
      name_en: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {category?.name_en}
        </MDTypography>
      ),
      name_ar: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {category?.name_ar}
        </MDTypography>
      ),

      description_en: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {category?.desc_en}
        </MDTypography>
      ),
      description_ar: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {category?.desc_ar}
        </MDTypography>
      ),
      icon: <MDAvatar src={category?.icon_url} alt="" size="xl" shadow="xs" />,
      rank: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {category?.rank}
        </MDTypography>
      ),
      created: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(category?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
        </MDTypography>
      ),
      action: (
        <Button
          variant="danger"
          onClick={() => {
            deleteCategory(category._id);
          }}
        >
          Delete
        </Button>
      ),

    })),
    data: data,
    loading: loading,
    refresh,
    setRefresh,
  };
}
