import React from "react";
import Lottie from "@amelix/react-lottie";
import loading from "./loading-state.json";

const LoadingAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        position: "relative",
        top: "300px",
      }}
    >
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  );
};

export default LoadingAnimation;
