import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart copy/configs";

const graphData = (gr) => {
  return gr?.map((row) => (row?.subscribers_count > 0 ? row?.subscribers_count / row?.user_count : 0)) || [];
};
const calculateAv = (g) => {
  let data = graphData(g);
  if (data?.length) {
    return data?.reduce((a, b) => a + b) / data.length;
  }
};

function ReportsBarChart({ color, title, description, date, chart, graph, dgraph, flag, totalFlag }) {
  const { options } = configs(chart || [], chart || {});
  // const options = "";

  const ddata = {
    labels: dgraph?.map((row) => moment(row.date).format("DD/MM")),
    datasets: [
      {
        label: "Subscribers/Day",
        data: dgraph?.map((row) => row?.subscribers_count),
        backgroundColor: "#ffffff",
      },
    ],
  };

  const data = {
    labels: graph?.map((row) => moment(row._id).format("MMM")),
    datasets: [
      {
        label: "Subscribers/Day",
        data: graph?.map((row) => row?.total_subscribers),
        backgroundColor: "#ffffff",
      },
    ],
  };
  const mdata = {
    labels: graph?.map((row) => moment(row.date).format("DD")),
    datasets: [
      {
        label: "Subscribers/Day",
        data: graph?.map((row) => row?.subscribers_count),
        backgroundColor: "#ffffff",
      },
    ],
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox variant="gradient" bgColor={color} borderRadius="lg" coloredShadow={color} py={2} pr={0.5} height="20.5rem">
              {totalFlag ? flag ? <Bar data={data} options={options} /> : <Bar data={mdata} options={options} /> : <Bar data={ddata} options={options} />}
            </MDBox>
          ),
          [chart, color]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
