import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import moment from "moment";

import configs from "examples/Charts/BarCharts/ReportsBarHTML/configs";

function ReportsBarChart({ color, chart, graph, dgraph, flag, totalFlag }) {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  const { options } = configs(chart || [], chart || {});

  // ddata is daily, mdata is monthly and data is yearly
  const ddata = {
    labels: dgraph?.map((row) => moment(row.date).format("DD/MM")),
    datasets: [
      {
        labels: dgraph?.map((row) => moment(row.date).format("DD/MM")),
        label: "website generated count",
        data: dgraph?.map((row) => row?.generated_websites_counts),
        backgroundColor: "rgba(245, 162, 145, 0.8)",
        borderColor: "rgba(245, 162, 145, 0.8)",
      },
    ],
  };

  const data = {
    labels: graph?.map((row) => moment(row._id).format("MMM")),
    datasets: [
      {
        labels: graph?.map((row) => moment(row._id).format("MM")),
        label: "website generated count",
        data: graph?.map((row) => row?.total_generated_websites_counts),
        backgroundColor: "rgba(245, 162, 145, 0.8)",
        borderColor: "rgba(245, 162, 145, 0.8)",
      },
    ],
  };

  const mdata = {
    labels: graph?.map((row) => moment(row.date).format("DD")),
    datasets: [
      {
        labels: graph?.map((row) => moment(row.date).format("DD")),
        label: "website generated count",
        data: graph?.map((row) => row?.generated_websites_counts),
        backgroundColor: "rgba(245, 162, 145, 0.8)",
        borderColor: "rgba(245, 162, 145, 0.8)",
      },
    ],
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox variant="gradient" bgColor={color} borderRadius="lg" coloredShadow={color} py={2} pr={0.5} height="20.5rem">
              {totalFlag ? flag ? <Bar data={data} options={options} /> : <Bar data={mdata} options={options} /> : <Bar data={ddata} options={options} />}
            </MDBox>
          ),
          [chart, color]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
