import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js";
import { Grid } from "@mui/material";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const ResponsesDistributionChart = ({ questionData }) => {
  const data1Array = questionData ? questionData[0] : [];
  const data2Array = questionData ? questionData[1] : [];
  const data3Array = questionData ? questionData[2] : [];

  const data1 = {
    labels: data1Array?.responses?.map((item) => item.answer),
    datasets: [
      {
        data: data1Array?.responses?.map((item) => item.percent),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF9F40"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF9F40"],
      },
    ],
  };

  const data2 = {
    labels: data2Array?.responses?.map((item) => item.answer),
    datasets: [
      {
        data: data2Array?.responses?.map((item) => item.percent),
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  const data3 = {
    labels: data3Array?.responses?.map((item) => item.answer),
    datasets: [
      {
        data: data3Array?.responses?.map((item) => item.percent),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF9F40",
          "#4BC0C0",
          "#9966FF",
          "#FF5733",
          "#C70039",
          "#900C3F",
          "#581845",
          "#FFC300",
          "#DAF7A6",
          "#33FF57",
          "#57D6FF",
          "#6E3DFF",
          "#B936FF",
          "#FF339B",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF9F40",
          "#4BC0C0",
          "#9966FF",
          "#FF5733",
          "#C70039",
          "#900C3F",
          "#581845",
          "#FFC300",
          "#DAF7A6",
          "#33FF57",
          "#57D6FF",
          "#6E3DFF",
          "#B936FF",
          "#FF339B",
        ],
      },
    ],
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4}>
        <Pie data={data1} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Pie data={data2} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Pie data={data3} />
      </Grid>
    </Grid>
  );
};

export default ResponsesDistributionChart;
