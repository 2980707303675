import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { baseURl } from "BaseUrl";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table } from "react-bootstrap";
import axios from "axios";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import CountUp from "react-countup";
import getNewUseCaseStats from "apis/useCaseAnalytics";
import { Button, Checkbox, Typography } from "@mui/material";
import ReportsBarChart from "examples/Charts/BarCharts/useCaseAnalytics";

import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";

function UseCase() {
  const [cases, setCase] = useState([]);
  const [sort, setSort] = useState("180deg");

  const [isLoading, setIsLoading] = useState(false);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [type, setType] = useState("year");
  const [category, setCategory] = useState("");

  const [flag, setFlag] = useState(false);

  const [analyticData, setAnalyticData] = useState([]);

  const [isCategory, setIsCategory] = useState(false);

  const useCaseData = async () => {
    setIsLoading(true);
    axios.get(`${baseURl}/admin/use-case-statistics/monthly?search=`).then((response) => {
      let arr = [...response?.data?.code_use_cases, ...response?.data?.images_use_cases, ...response?.data?.text_use_cases, ...response?.data?.audio_use_cases, ...response?.data?.video_use_cases];
      let arr1 = arr.sort((a, b) => b.count - a.count);
      setCase(arr1);
      setIsLoading(false);
    });
  };

  const useCaseAnalytics = async () => {
    const data = await getNewUseCaseStats(year, month, type, category);
    setAnalyticData(data?.data?.data);
  };

  const sorting = () => {
    cases.reverse();
    setSort(sort === "180deg" ? "0deg" : "180deg");
  };

  useEffect(() => {
    useCaseData();
  }, []);

  useEffect(() => {
    useCaseAnalytics();
  }, [year, month, type, category]);

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const categoryTypes = ["image", "audio", "business", "code", "video", "writing"];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card sx={{ overflow: "auto" }}>
                  <MDBox
                    mx={2}
                    mt={2}
                    py={1.5}
                    px={1.5}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <MDTypography variant="h5" color="white">
                      Use Case
                    </MDTypography>
                  </MDBox>
                  <MDBox mx={2} mt={2} py={1.5} px={1.5}>
                    <MDTypography
                      variant="h5"
                      sx={{
                        py: 1,
                      }}
                    >
                      Analytics
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    sx={{
                      py: 1,
                      mx: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <select
                      id="month"
                      disabled={type === "year" ? true : false}
                      onChange={(e) => {
                        setMonth(e.target.value);
                        setType("month");
                        setFlag(!flag);
                      }}
                      value={month}
                      style={{ border: "none" }}
                    >
                      {monthNames.map((item, index) => {
                        return <option value={index + 1}>{item}</option>;
                      })}
                    </select>
                    <select
                      disabled={type === "month" ? true : false}
                      onChange={(e) => {
                        setYear(e.target.value);
                        setType("year");
                        setFlag(!flag);
                      }}
                      id="year"
                      value={year}
                      style={{ border: "none" }}
                    >
                      {["2023", "2024"].map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>

                    <select
                      onChange={(e) => {
                        setCategory(e.target.value);
                        setFlag(!flag);
                      }}
                      value={category}
                      style={{ border: "none" }}
                    >
                      {categoryTypes.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>

                    <Typography variant="button" style={{ color: "#7b809a", textTransform: "uppercase", fontSize: "0.65rem" }}>
                      Categorized
                    </Typography>
                    <Checkbox
                      onChange={(e) => {
                        setIsCategory(e.target.checked ? true : false);
                        setFlag(!flag);
                      }}
                      checked={isCategory}
                    />
                    <Typography variant="button" style={{ color: "#7b809a", textTransform: "uppercase", fontSize: "0.65rem" }}>
                      Yearly
                    </Typography>
                    <Checkbox
                      onChange={(e) => {
                        setType(e.target.checked ? "year" : "month");
                        setFlag(!flag);
                      }}
                      checked={type === "year"}
                    />
                    {/* reset to default */}
                    <Button
                      onClick={() => {
                        setYear(new Date().getFullYear());
                        setMonth(new Date().getMonth() + 1);
                        setType("year");
                        setCategory("");
                        setIsCategory(false);
                        setFlag(!flag);
                      }}
                      variant="contained"
                      color="error"
                    >
                      Reset
                      <RestartAltRoundedIcon />
                    </Button>
                  </MDBox>

                  <ReportsBarChart color="success" title="Use Case Anlaytics" graph={analyticData} types={categoryTypes} flag={flag} />

                  <MDBox>
                    <MDBox sx={{ margin: "10px 20px" }}>
                      <Table>
                        <thead>
                          <tr style={{ color: "#7b809a", textTransform: "uppercase", fontSize: "0.65rem", cursor: "pointer" }} onClick={() => sorting()}>
                            <th>
                              Use Case <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                            <th>
                              Language <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                            <th>
                              Style <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                            <th>
                              Count <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cases?.map((item, index) => (
                            <tr key={index} style={{ color: "#7b809e", fontSize: "0.85rem" }}>
                              <td>{item?.useCase ? item?.useCase.replace("Create", "Generate Image") : "Code"}</td>
                              <td>{item?.language}</td>
                              <td>{item?.style ? item?.style : "-"}</td>
                              <td>
                                <CountUp end={item?.count} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default UseCase;
