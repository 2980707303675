import React from "react";
import { Spinner } from "react-bootstrap";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import moment from "moment";
import MDBadge from "components/MDBadge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Toolbar, Tooltip } from "@mui/material";
import "./style.css";

const UserTable = ({ data, isLoading, width, details, page, limit, setLimit, setPage }) => {
  console.log(page, "init");
  const handlePageClick = (page) => {
    setPage(page);
  };
  const renderPaginationOptions = () => {
    const paginationOptions = [];
    let startPage = 1;
    let endPage = Math.min(details?.totalPages, 5);
    if (details?.totalPages > 5) {
      if (page <= 3) {
        endPage = 5;
      } else if (page >= details?.totalPages - 2) {
        startPage = details?.totalPages - 4;
        endPage = details?.totalPages;
      } else {
        startPage = page - 2;
        endPage = page + 2;
      }
    }
    if (startPage > 1) {
      paginationOptions.push(renderPaginationButton(1));
      paginationOptions.push(<span key="first-ellipsis">...</span>);
    }
    for (let i = startPage; i <= endPage; i++) {
      paginationOptions.push(renderPaginationButton(i));
    }
    if (endPage < details?.totalPages) {
      paginationOptions.push(<span key="last-ellipsis">...</span>);
      paginationOptions.push(renderPaginationButton(details?.totalPages));
    }
    return paginationOptions;
  };

  const renderPaginationButton = (i) => (
    <button
      key={i}
      onClick={() => {
        handlePageClick(i);
        console.log(i, "III");
      }}
      style={{
        background: page === i ? "linear-gradient(270deg, #151723, #23273A)" : null,
        color: page === i ? "white" : null,
      }}
    >
      {i}
    </button>
  );
  // console.log(details, "details");
  return (
    <>
      {data?.length === 0 ? (
        <center style={{ margin: "40px 0" }}>{"No Transaction"}</center>
      ) : (
        <>
          {isLoading ? (
            <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} animation="border" role="status" />
          ) : (
            <div className="table_container">
              <div className="table_header">
                {["Name", "Date", "Transaction", "Data"]
                  .filter((_, index) => width >= 600 || index !== 3)
                  .map((items, index) => (
                    <div
                      key={index}
                      className="table_header_item"
                      style={{
                        width: items === "Name" ? "10%" : items === "Date" ? "15%" : items === "Transaction" ? "20%" : items === "Data" ? "15%" : "15%",
                        textAlign: items === "Use Case" || items === "Word Count" || "Date" ? "center" : null,
                      }}
                    >
                      {items}
                    </div>
                  ))}
              </div>
              <div className="table_body">
                {data?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="table_body_item"
                      style={{
                        background: index % 2 === 0 ? "transparent" : null,
                      }}
                    >
                      <div className="table_body_item_useCase" style={{ minWidth: "15%", textAlign: "center" }}>
                        <h6>
                          {item?.product_id.name}
                          {item?.status == "active" ? (
                            <Tooltip title={item?.status}>
                              <MDBadge badgeContent=<CheckCircleIcon /> color={item?.status === "active" ? "success" : "error"} variant="gradient" size="sm" />
                            </Tooltip>
                          ) : (
                            <Tooltip title={item?.status}>
                              <MDBadge badgeContent=<CancelIcon /> color="error" variant="gradient" size="sm" />
                            </Tooltip>
                          )}
                        </h6>
                      </div>

                      <div className="table_body_item_date" style={{ minWidth: "15%", textAlign: "center" }}>
                        <h6 style={{ textAlign: "start" }}>
                          Start :{moment(item?.start_date)?.format("MMMM Do YYYY")}
                          <br />
                          End :{moment(item?.end_date)?.format("MMMM Do YYYY")}
                        </h6>
                      </div>
                      <div
                        className="table_body_item_text"
                        style={{ minWidth: "20%", maxWidth: "20%", padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "column", fontFamily: "Roboto" }}
                      >
                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>paid at : {moment(item.last_transaction_id.paid_at).format("DD/MM/YYYY,h:mm:ss a")}</h6>
                        <h6 style={{ textAlign: "start", fontWeight: "bold" }}>amount paid : {item?.last_transaction_id.total_amount}</h6>
                        <h6 style={{ textAlign: "start", fontWeight: "bold" }}>payment gate : {item.last_transaction_id.paymentGate}</h6>
                      </div>
                      {width >= 600 && (
                        <div
                          className="table_body_item_word_count"
                          style={{
                            minWidth: "15%",
                            padding: "0 10px",
                            textAlign: "start",
                          }}
                        >
                          No of words:
                          {item?.starting_no_of_words} <br />
                          No of images:
                          {item?.starting_no_of_images}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="table_footer">
                <div className="table_footer_item">
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                    value={limit}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                  </select>
                  <div className="table_footer_buttons">
                    <button
                      style={{ background: "none" }}
                      disabled={!details?.hasPrevPage}
                      onClick={() => {
                        page > 1 ? setPage((page) => (page = page - 1)) : null;
                        console.log(page, "page");
                      }}
                    >
                      <ArrowBackIosNewRoundedIcon fontSize="small" sx={{ color: !details?.hasPrevPage ? "black" : null }} />
                    </button>
                    {renderPaginationOptions()}
                    <button
                      style={{ background: "none" }}
                      disabled={!details?.hasNextPage}
                      onClick={() => {
                        console.log(details.hasNextPage, "next");

                        page < details.totalPages ? setPage((page) => (page = page + 1)) : null;
                        console.log(page, "page");
                      }}
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" sx={{ color: !details?.hasNextPage ? "black" : null }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserTable;
