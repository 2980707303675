import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { baseURl } from "BaseUrl";
import moment from "moment";

export default function data() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    planData();
  }, [refresh, count, limit]);

  const planData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseURl}/training-invitation/stats`);
      const allPlans = res?.data?.data?.stats;
      setData(allPlans);
      setTotal(res?.data?.data.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // planData();
  return {
    columns: [
      { Header: "Date", accessor: "date", align: "left" },
      { Header: "Count", accessor: "count", align: "left" },
      { Header: "hour", accessor: "hour", align: "left" },
    ],

    rows: data?.map((plan) => ({
      row: plan,
      date: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {moment(plan.date).format("DD-MM-YYYY")}
        </MDTypography>
      ),

      count: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <span>{plan.count}</span>
        </MDTypography>
      ),

      hour: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <span>{plan?.hour}</span>
        </MDTypography>
      ),
    })),
    data: data,
    loading: loading,
    refresh,
    setRefresh,
    alert,
    limit: limit,
    setLimit: setLimit,
    count: count,
    setCount: setCount,
    total: total,
  };
}
