import Axios from "axios";
const BaseURL = Axios.create({
    baseURL: process.env.REACT_APP_BLOG_URL,
});

const token = localStorage.getItem("token");
if (token) {
    BaseURL.defaults.headers.common["Authorization"] = `${token}`;
}
BaseURL.defaults.headers.post["Content-Type"] = "application/json";


const addBlog = (data) => {
    return BaseURL.postForm(`/admin/blog`, data);
};

const allBlog = (count, limit) => {
    return BaseURL.get(`/admin/blog?page=${count}&limit=${limit}`);
};

const deleteBlog = (id) => {
    return BaseURL.delete(`/admin/blog/${id}`);
};

const getBlogById = (id) => {
    return BaseURL.get(`/admin/blog/${id}`);
}
const updateBlog = (id, data) => {
    return BaseURL.putForm(`/admin/blog/${id}`, data);
};


const Blog = {
    addBlog,
    allBlog,
    deleteBlog,
    getBlogById,
    updateBlog
};

export default Blog;
