import React from 'react';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom'; // Import Link from React Router to handle redirection
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import Card1 from './Card1';
// import Card2 from './Card2';
// import Card3 from './Card3';
// import Card4 from './Card4';
// import Card5 from './Card5';

const CardsPage = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/coursecategory">
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Add category <AddRoundedIcon /></Typography>
                                <Typography variant="body2">Click here to add new categories to courses</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/courseskill">
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Add Skills<AddRoundedIcon /></Typography>
                                <Typography variant="body2">Click here to add new skills to courses</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/course-features">
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Add Features <AddRoundedIcon /></Typography>
                                <Typography variant="body2">Click here to add new features to courses</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/courseinstructor">
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Add instructors <AddRoundedIcon /></Typography>
                                <Typography variant="body2">Click here to add new instructors to courses</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/viewcourses">
                        <Card>
                            <CardContent>
                                <Typography variant="h5">View all courses</Typography>
                                <Typography variant="body2">Click here to view all courses.</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Link to="/coursevideo">
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Add course Video <AddRoundedIcon /></Typography>
                                <Typography variant="body2">Click here to add videos to courses.</Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default CardsPage;
